import React,{useState,useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';
import './css/H1B_Applicants.css';
import Headerlogo from './images/H1B_Applicants_logo.png';
import Employerlogo from './images/Employer.svg';
import Applicantlogo from './images/Applicant.svg';
import Attorneylogo from './images/Attorney.svg';
import AuthUser from './AuthUser';
function Home() {
    const [usersettings,setUsersettings]=useState({});
    const { http,getUser,getUsertype} = AuthUser();
    let userdetails = getUser();
    let usertype = getUsertype();
    const navigate = useNavigate();
    const isAuthenticated = (userdetails != '' && userdetails != null) ? true : false;
   
    useEffect(() => {
        /*
        if(isAuthenticated){
           if(usertype === 'admin' || usertype === 'applicant' || usertype === 'attorney' || usertype === 'employer'){
            navigate(`/${usertype}`)
           } 
        }else{
        */
        
            http.get('/usersettings')
            .then((response) => {
                console.log(response.data);
                setUsersettings(response.data.usersettings);
                sessionStorage.setItem('usersettings',JSON.stringify(response.data.usersettings));
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            });
        //}
        
    }, []);
    const [id, setId] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        if (id) {
            navigate(`/searchpfadata/${id}`);
        }
    };
  return (
    <>
        <nav className="navbar navbar-expand-lg navbar-light bg-white fixed-top">
            <div className="container-fluid">
                <a href="/">
                    <img src={Headerlogo} alt="H1BAPPLICANTS.COM" width="70%" />
                </a>
            </div>
        </nav>
        <div className="container-fluid text-dark mt-5 pt-3 mb-5" style={{ backgroundColor:'#f6fafc'}}>
            <div className="col-md-5 m-auto">
                <div className="row mt-3">
                    <div className="col-md-12">
                        <h6 className="mb-2 text-center">Public Access files</h6>
                        <form onSubmit={handleSubmit}>
                            <div className="input-group mb-3">
                            <div className='input-group-prepend' style={{ width: '100%'}}>
                            <input type="text" value={id} onChange={(e) => setId(e.target.value)} className='form-control' name='pfacase' placeholder='Enter Public Access Files Case No' />
                            <button className="btn btn-primary btn-sm border-0 rounded-0" style={{ backgroundColor:'#DF8E2E' }} type="submit">Submit</button>
                            </div>
                            </div>
                        </form> 
                        <div className="card text-center">
                            <div className="card-body border-bottom">                                
                                                                                             
                                <h6 className="mb-4">Applicant/Beneficiary</h6>
                                <img src={Applicantlogo} alt="Applicant/Beneficiary" />
                                <p className="card-text mt-4 text-dark">
                                    <strong>Applicants can view, upload documents and create tickets.</strong>
                                </p>
                                <div className="gap-2 mt-4"> 
                                    <a className="btn btn-primary border-0 rounded-0 " href="/applicantlogin" style={{ backgroundColor:'#2488B3' }}>Login</a>&nbsp;
                                    <a className="btn btn-primary border-0 rounded-0" href="/applicantregister" style={{ backgroundColor:'#DF8E2E' }}>Register</a>
                                </div>
                            </div>
                            <div className="row mt-4">
                                    <div className="col-md-6" style={{ borderRight: '1px solid #E3E3E3' }}>
                                        <div className=" text-center">
                                            <div className="card-body">
                                                <h6 className="mb-4">Employer</h6>
                                                <img src={Employerlogo} alt="Employer" width="25%" />
                                                <p className="card-text mt-4 text-dark">
                                                    <strong>Employers can View &amp; Manage the cases online.</strong>
                                                </p>
                                                <div className=" gap-2 mt-4">
                                                    <a className="btn btn-primary border-0 rounded-0" href="/employerlogin" style={{ backgroundColor:'#2488B3' }}>Login</a>&nbsp;
                                                    {usersettings.employer_register==='Y' && <a className="btn btn-primary border-0 rounded-0" href={usersettings.employer_register==='Y' && `/employerregister`} style={{ backgroundColor:'#DF8E2E' }}>Register</a>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className=" text-center">
                                            <div className="card-body">
                                                <h6 className="mb-4">Attorney</h6>
                                                <img src={Attorneylogo} alt="Attorney" width="38%" />
                                                <p className="card-text mt-4 text-dark">
                                                    <strong>Attorney can view &amp; manage cases online.</strong>
                                                </p>
                                                <div className=" gap-2 mt-4">
                                                    <a className="btn btn-primary border-0 rounded-0" href="/attorneylogin" style={{ backgroundColor:'#2488B3' }}>Login</a>&nbsp;
                                                    {usersettings.attorney_register==='Y' && <a className="btn btn-primary border-0 rounded-0" href={usersettings.employer_register==='Y' && `/attorneyregister`} style={{ backgroundColor:'#DF8E2E' }}>Register</a>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-5 mt-3 m-auto text-center">
                <p><strong>Owned &amp; managed by </strong></p>
            </div>
            <div className="col-md-5 mt-3 m-auto text-center">
                <p><strong>TransNational Software Services Inc. </strong></p>
            </div>
        </div>
    </>
  )
}

export default Home
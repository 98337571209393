import React,{useState,useEffect} from 'react'
import AuthUser from '../AuthUser';
import Modal from 'react-bootstrap/Modal';
function Showlca(props) {
    const { http, getUser } = AuthUser();
    const[showlca,setShowlca]=useState({});
    const[getlocations,setGetlocations]=useState([]);
    useEffect(() => {
        if(props.id !== ''){
            http.get(`${props.usertype}/showlca?id=${props.id}`)
            .then((response) => {
                console.log(response.data);
                setShowlca(response.data.showlca);
                setGetlocations(response.data.getlocations);
            })
            .catch(function (error) {
                // handle error
                console.log(error.response.data.error);
            });
        }
        
    }, [props.id]);
  return (
    <>
        <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" show={props.show} onHide={props.close} centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Show Lca</Modal.Title>
            </Modal.Header>
            <Modal.Body> 
            <div className="row mb-4">
                <div className="mb-2 col-md-6">
                    <label className="form-label " style={{ color: '#2D8BB4' }}><strong>FIRST NAME</strong></label>
                    <p>{showlca.firstname }</p>
                </div>
                <div className="mb-2 col-md-6">
                    <label className="form-label " style={{ color: '#2D8BB4' }}><strong>MIDDLE NAME</strong></label>
                    <p>{ showlca.middlename }</p>
                </div>
                <div className="mb-2 col-md-6">
                    <label className="form-label " style={{ color: '#2D8BB4' }}><strong>LAST NAME</strong></label>
                    <p>{ showlca.lastname }</p>
                </div>
                <div className="mb-2 col-md-6">
                    <label className="form-label " style={{ color: '#2D8BB4' }}><strong>JOB TITLE</strong></label>
                    <p>{ showlca.jobtitle }</p>
                </div>
                {getlocations.map(getlcalocation => (
                    <div className="col-md-12">
                        <label className="form-label " style={{ color: '#2D8BB4' }}><strong>OPERATING AT THE WORK LOCATION</strong></label>
                        <p>{ getlcalocation.operating_at }</p>
                        <label className="form-label"><strong>CLIENT NAME</strong></label>
                        <p>{ getlcalocation.client_name }</p>
                        <label className="form-label mt-2"><strong>WORK LOCATION 1</strong></label>
                        <p> { getlcalocation.work_location }</p>
                    </div>                    
                    
                ))}
                <div className="col-md-12">
                    <label className="form-label " style={{ color: '#2D8BB4' }}><strong>WAGE LEVEL</strong></label>
                    <p>{ showlca.wagelevel }</p>
                </div>
                <div className="mb-2 col-md-12 mt-4">
                    <label className="form-label " style={{ color: '#2D8BB4' }}><strong>JOB DUTIES</strong></label>
                    <p>{ showlca.jobduties } </p>
                </div>
                <div className="mb-2 col-md-12">
                    <label className="form-label" style={{ color: '#2D8BB4' }}><strong>EMPLOYEE COUNT AS OF TODAY</strong></label>
                    <p>{ showlca.empcount }</p>
                </div>
            </div>
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal>
    </>
    
  )
}

export default Showlca
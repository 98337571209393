import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Routes, Route, Link, Router,Navigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import AuthUser from '../AuthUser';
import Headerlogo from '../images/H1B_Applicants_logo.png';
import ApplicantDashboard from '../Applicants/ApplicantDashboard';
import Tickets from '../Applicants/Tickets';
import SampleDocuments from '../Common/Sampledocuments';
import Changepassword from '../Common/Changepassword';
import Myprofile from '../Common/Myprofile';
import Mydocuments from '../Applicants/Mydocuments';
import H1bdocuments from '../Common/H1bdocuments';
import Mytickets from '../Applicants/Mytickets';
import Editprofile from '../Common/Editprofile';
import '../css/H1B_Admin_Dashboard.css';
import Resetpassword from '../Common/Resetpassword';
import Insurancevendorinfo from '../Applicants/Insurancevendorinfo';
import Newemployeedocumentation from '../Applicants/Newemployeedocumentation';
import Paychexlogin from '../Applicants/Paychexlogin';
import Payrollcalendar from '../Applicants/Payrollcalendar';
import Performacereview from '../Applicants/Performancereview';
import Suggestions from '../Applicants/Suggestions';
import Feedbacks from '../Applicants/Feedbacks';
import Timesheets from '../Applicants/Timesheets';
import H1bdocumentation from '../Applicants/H1bdocumentation';
import Myrequests  from '../Applicants/Myrequests';
import Myreportingmanager from '../Applicants/Myreportingmanager';
import Referralpolicy from '../Applicants/Referralpolicy';
import Viewperformancereview from '../Applicants/Viewperformancereview';
import Timesheetreport from '../Applicants/Timesheetreport';
import Myreferrals from '../Applicants/Myreferrals';
import PrivateRoute from './PrivateRoute';
import Home from '../Home';

function ApplicantNav() {
    const {token,logout,getUser} = AuthUser();
    let userdetails = getUser();
    let loguser = userdetails.name+" "+userdetails.lastname;
    const logoutUser = () => {
        if(token !== undefined){
            logout();
        }
    }
    
        const isAuthenticated = (userdetails !== '') ? true : false;
    
  return (
    <div>        
        <Navbar expand="md" className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
            <Navbar.Brand href="/applicant"><img src={Headerlogo} width="80%" alt="H1B Applicants" /></Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
            <NavDropdown className="navbar-nav ml-auto" title={loguser} >
                <NavDropdown.Item href="/applicant/myprofile"><i className="bi-person-fill"></i>My Profile</NavDropdown.Item>
                <NavDropdown.Item href="/applicant/editprofile"><i className="bi-person-fill"></i>Edit Profile</NavDropdown.Item>
                <NavDropdown.Item href="/applicant/changepassword"><i className="bi-key-fill"></i>Change Password</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={logoutUser}>Logout</NavDropdown.Item>
            </NavDropdown>
            </Navbar.Collapse>
        </Navbar>
        <Routes>
            <Route path="/applicant" element={ isAuthenticated ? <ApplicantDashboard logintype="applicant"/> : <Navigate to="/" replace /> } />
            <Route path="/applicant/tickets" element={isAuthenticated ? <Tickets logintype="applicant"/> : <Navigate to="/" replace />}  />
            <Route path="/applicant/mytickets" element={isAuthenticated ? <Mytickets logintype="applicant" type="All" /> : <Navigate to="/" replace /> } />
            <Route path="/applicant/opentickets" element={isAuthenticated ? <Mytickets logintype="applicant" type="O" /> : <Navigate to="/" replace /> } />
            <Route path="/applicant/approvedtickets" element={isAuthenticated ? <Mytickets logintype="applicant" type="A" /> : <Navigate to="/" replace /> } />
            <Route path="/applicant/worktickets" element={isAuthenticated ? <Mytickets logintype="applicant" type="W" /> : <Navigate to="/" replace /> } />
            <Route path="/applicant/closedtickets" element={ isAuthenticated ? <Mytickets logintype="applicant" type="C" /> : <Navigate to="/" replace /> } />
            <Route path="/applicant/sampledocuments" element={isAuthenticated ? <SampleDocuments logintype="applicant" pageview="Sample Documents" apiurl="sampledocsapi" /> : <Navigate to="/" replace /> } />
            <Route path="/applicant/changepassword" element={isAuthenticated ? <Changepassword logintype="applicant"/> : <Navigate to="/" replace />  } />
            <Route path="/applicant/myprofile" element={ isAuthenticated ? <Myprofile logintype="applicant" /> : <Navigate to="/" replace />   } />
            <Route path="/applicant/mydocs" element={isAuthenticated ? <Mydocuments logintype="applicant" pageview="My Documents" dtype="DOCS"/> : <Navigate to="/" replace /> } />
            <Route path="/applicant/gcdocs" element={isAuthenticated ? <Mydocuments logintype="applicant" pageview="GC Documents"  dtype="GCDOCS" /> : <Navigate to="/" replace /> } />
            <Route path="/applicant/h1bQuestionaire" element={isAuthenticated ? <H1bdocuments logintype="applicant" /> : <Navigate to="/" replace />  } />
            <Route path="/applicant/editprofile" element={isAuthenticated ? <Editprofile logintype="applicant"/> : <Navigate to="/" replace />   } />
            <Route path="/applicant/resetpassword/:token/:email" element={ isAuthenticated ? <Resetpassword logintype="applicant" pageview="Reset Password" apiurl="passwordUpdate" /> : <Navigate to="/" replace />   } />
            <Route path="/applicant/createpassword/:email" element={isAuthenticated ? <Resetpassword logintype="applicant" pageview="Create Password" apiurl="updatePassword" /> : <Navigate to="/" replace />  } />
            <Route path="/applicant/newempdocuments" element={ isAuthenticated ? <Newemployeedocumentation logintype="applicant" apiurl="empnewdocuments" /> : <Navigate to="/" replace />   }/>
            <Route path="/applicant/paychexlogin" element={ isAuthenticated ? <Paychexlogin logintype="applicant"/> : <Navigate to="/" replace />    } />
            <Route path="/applicant/payrollcalendar" element={isAuthenticated ? <Payrollcalendar logintype="applicant" /> : <Navigate to="/" replace />  } />
            <Route path="/applicant/performancereview" element={isAuthenticated ? <Performacereview logintype="applicant"/> : <Navigate to="/" replace />  } />
            <Route path="/applicant/suggestion" element={isAuthenticated ? <Suggestions logintype="applicant"/> : <Navigate to="/" replace /> } />
            <Route path="/applicant/feedback" element={isAuthenticated ? <Feedbacks logintype="applicant"/> : <Navigate to="/" replace /> } />
            <Route path="/applicant/timesheet" element={isAuthenticated ? <Timesheets logintype="applicant"/> : <Navigate to="/" replace /> } />
            <Route path="/applicant/h1bdocumentations" element={isAuthenticated ? <H1bdocumentation logintype="applicant" /> : <Navigate to="/" replace />  } />
            <Route path="/applicant/myrequests" element={isAuthenticated ? <Myrequests logintype="applicant" /> : <Navigate to="/" replace />  } />
            <Route path="/applicant/insurancevendorinfo" element={isAuthenticated ? <Insurancevendorinfo logintype="applicant" /> : <Navigate to="/" replace /> }/>
            <Route path="/applicant/myreportingmanager" element={isAuthenticated ? <Myreportingmanager logintype="applicant" /> : <Navigate to="/" replace />}/>
            <Route path="/applicant/referralpolicy" element={isAuthenticated ? <Referralpolicy logintype="applicant"/> : <Navigate to="/" replace /> } />
            <Route path="/applicant/myreferrals" element={isAuthenticated ? <Myreferrals logintype="applicant"/> : <Navigate to="/" replace />  } />
            <Route path="/applicant/viewperformancereview" element={isAuthenticated ? <Viewperformancereview logintype="applicant"/> : <Navigate to="/" replace />  } />
            <Route path="/applicant/timesheetreport/:id" element={isAuthenticated ? <Timesheetreport logintype="applicant"/> : <Navigate to="/" replace />  } />
            <Route path="*" element={<Home />} />
        </Routes>         
    </div>
  )
}

export default ApplicantNav
import React,{useState,useEffect} from 'react'
import AuthUser from '../AuthUser';
function Mytickets(props) {
    let usertype = props.logintype;
    const { http, getUser } = AuthUser();
    let userdetails = getUser();
    const [ticketslist, setTicketslist] = useState([])
    const [filter, setFilter] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    useEffect(() => {
        http.get(`${usertype}/showtickets?email=${userdetails.email}&showTicket=${props.type}`)
          .then((response) => {
            //console.log(response.data);
            setTicketslist(response.data.mytickets);
            setFilteredData(response.data.mytickets);
          })
          .catch(function (error) {
            // handle error
            console.log(error.response.data.error);
          });
      }, []);
    const handleFilterChange = (e) => {
        const value = e.target.value.toLowerCase();
        setFilter(value);

        const filtered = ticketslist.filter(item =>
            item.name.toLowerCase().includes(value) || item.lastname.toLowerCase().includes(value) || item.ticket.toLowerCase().includes(value)
            // Add more conditions based on your data structure
        );

        setFilteredData(filtered);
    };
  return (
    <div className="container-fluid">
        <div className="row mb-2 ">

            <div className="col-md-6">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb p-0">
                        <li className="breadcrumb-item "><a href="/applicant">Dashboard</a></li>
                        <li className="breadcrumb-item "><a>View my tickets</a></li>
                    </ol>
                </nav>
            </div>
            <div className="col-md-4 small  text-right">
                <div className="row">
                    <input type="text" name="email" value={filter}
                onChange={handleFilterChange} className="form-control searchEmail" placeholder="Search by ticket name, ticket by, open or close..." />
                </div>
            </div>

        </div>
        <div className="col-lg-9 m-auto  text-dark">
            <div className="row">
                <div className="col-md-2">
                    <ul className="list-group list-group-flush bg-transparent font-weight-bold small navbar-nav-main">
                        <li className="list-group-item bg-transparent border-0">
                            <a href="/applicant/mytickets" id="all" className="ticketShow text-dark text-decoration-none">My Tickets</a>
                        </li>
                        <li className="list-group-item bg-transparent border-0">
                            <a href="/applicant/opentickets" id="open" className="ticketShow text-dark text-decoration-none">Open</a>
                        </li>
                        <li className="list-group-item bg-transparent border-0">
                            <a href="/applicant/approvedtickets" id="approved" className="ticketShow text-dark text-decoration-none">Approved</a>
                        </li>
                        <li className="list-group-item bg-transparent border-0">
                            <a href="/applicant/worktickets" id="work" className="ticketShow text-dark text-decoration-none">Work In Progress</a>
                        </li>
                        <li className="list-group-item bg-transparent border-0">
                            <a href="/applicant/closedtickets" id="close" className="ticketShow text-dark text-decoration-none">Close</a>
                        </li>
                    </ul>
                    <input type="hidden" name="ticketallow" className="form-control searchTicket" />
                </div>
                <div className="col-md-10 bg-white shadow-sm pb-5">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card  mb-4">
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table data-toggle="table" data-classes="table table-hover table-condensed"
                                                data-striped="true" data-sort-name="Quality" data-sort-order="desc"
                                                id="Tabla" className="Tabla table table-hover table-condensed" data-page-length='10'>
                                                <thead>
                                                    <tr>
                                                        <th data-sortable="true">Ticket By</th>
                                                        <th>Ticket For</th>
                                                        <th>Status</th>
                                                        <th data-sortable="true">Submitted Date / Time</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {filteredData.map(ticket => (
                                                    <tr key={ticket.id}>
                                                        <td>{ ticket.name}&nbsp;{ ticket.lastname}</td>
                                                        <td>{ ticket.ticket}</td>
                                                        <td>{ ticket.status == 'C' ? 'Close' : ticket.status == 'A' ? 'Approve' : 'Open'}</td>
                                                        <td>{ ticket.created_at }</td>
                                                    </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Mytickets
import React,{useState,useEffect} from 'react'
import AuthUser from '../AuthUser';
function Paychexlogin(props) {
  const { http, getUser } = AuthUser();
    let userdetails = getUser();
    let usertype = props.logintype;
    let dashboard=`/${usertype}`;
  return (
      <div className="container-fluid">
        <div className="col-md-6 m-auto p-0 pb-2 ">
          <h6 className="mb-2 font-weight-bold"></h6>
        </div>
        <div className="col-md-6 m-auto p-0 pb-2 ">
            <div className='row'>
                <div className="col-md-6 text-left pull-left">
                    <h6 className="mb-2 font-weight-bold">Paychex Login Info</h6>
                </div>
                <div className="col-md-6 text-right pull-right">
                    <a href={dashboard} className="mb-2 font-weight-bold">Back</a>           
                </div>
            </div>
        </div>
        <div className="col-md-6 bg-white m-auto shadow-sm p-4 text-center" style={{ borderTop: '5px solid #DF8E2E' }}>
          <div className="row">
            <p>Create Account at </p>
            <h6 style={{ color: '#2B91BD' }}> <a href="https://myapps.paychex.com" target="_blank" class="text-primary">https://myapps.paychex.com</a></h6> 
            <p></p>
            <p>Sign in with your created user id and password to view pay stubs and tax documents. </p>
          </div>
        </div>
      </div>
  )
}

export default Paychexlogin
import React,{useState} from 'react'
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import Headerlogo from '../images/H1B_Applicants_logo.png';
import Footerlogo from '../images/tns-logo.jpg';
import AuthUser from '../AuthUser';

function Forgot() {
    const location = useLocation();
    const {http,getUser} = AuthUser();
    let usertype = location.pathname == '/applicantforgot' ? 'applicant' : location.pathname == '/attorneyforgot' ? 'attorney' : location.pathname == '/employerforgot' ? 'employer' : '';
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };
    const validateEmail = () =>{
        let isValid = true;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        // Validate email
        if (!email.trim()) {
            setError('Email required');
            isValid = false;
        } else  if(!emailRegex.test(email)){
        setError('Email not valid');
        isValid = false;
        }else {
            setError();
        }
        return isValid;
    };
    const handleSubmit = async (e) => {
        e.preventDefault(); 
        if (validateEmail()) {
            http.post(`${usertype}/forgot`,{email:email}).then((response)=>{
                //setToken(response.data.userdetail,response.data.token);
                //console.log(response.status);
                console.log(response.data);
                setSuccessMessage(response.data.message);
                setEmail('');
                
            }).catch(function (error) {
              // handle error
              console.log(error);
              //console.log(error.response.data.error);
              setError(error.response.data.error);
                 
              //setsubmitted(false)
            })
          } else {
            // Form is not valid, display error messages
      
          }    
        
    };
  return (
    <div className="col-md-12 ">
        <div className="row mt-5">
            <div className="col-md-3 border mt-5 bg-white m-auto">
                <section className="p-4 right-section ">
                    <div className="col-lg-10 m-auto  "> 
                        <a href="/"><img src={Headerlogo} alt="H1BAPPLICANTS.COM" width="100%" /></a>
                        <h5 className="mt-4">Forgot Password</h5>
                        <form method="POST" onSubmit={handleSubmit} id="forgotpost">                            
                            <div className="col-md-12 ">
                                <div className="input-container ic1">
                                    <input type="email" value={email} onChange={handleEmailChange} className="form-control rounded-0 mt-2 mb-2 input" name="email"
                                        id="Email" placeholder=" " />
                                    <div className="cut"></div>
                                    <label className="placeholder">Email *</label>
                                    <div className="invalid-feedback small">{error}</div>
                                    <div className="alert-success small">{successMessage}</div>
                                </div>
                                
                            </div>
                            <div className="col-md-12 text-center d-grid">
                                <button type="submit" className="btn text-white mt-2 rounded-0"
                                    style={{ backgroundColor: '#2488B3' }}><strong>Submit</strong></button>
                            </div>
                        </form>
                        <div className="col-md-12 m-auto text-center bg-white p-2 mt-4">
                            <a href="https://www.tnsservices.com/" target="_blank"><img src={Footerlogo} width="70%" alt="Trans National Software Services Inc." /></a>
                        </div>
                    </div>
                </section>

            </div>
        </div>
    </div>
  )
}

export default Forgot
import React,{useState,useEffect} from 'react'
import AuthUser from '../AuthUser';
import Accordion from 'react-bootstrap/Accordion';
function Feedbacklist(props) {
    let usertype = props.logintype;
    let dashboard=`/${usertype}`;
    let apiurl = props.apiurl;
    const { http, getUser } = AuthUser();
    let userdetails = getUser();
    const [feedbacks,setFeedbacks] = useState([]);
    function fetchItems(){
        http.get(`${usertype}/${apiurl}/${userdetails.email}`)
        .then((response) => {
            console.log(response.data);
            setFeedbacks(response.data.feedbacks);
        })
        .catch(function (error) {
            // handle error
            console.log(error.response.data.error);
        });
    }
    useEffect(() => {
        fetchItems();
    }, []);
  return (
    <div className="container-fluid">
        <div className="col-md-9 m-auto p-0 pb-2 ">
            <div className='row'>
                <div className="col-md-6 text-left pull-left">
                    <h6 className="mb-2 font-weight-bold">Feedback</h6>
                </div>
                <div className="col-md-6 text-right pull-right">
                    <a href={dashboard} className="mb-2 font-weight-bold">Back</a>           
                </div>
            </div>
        </div>
        <div className="col-md-9 bg-white m-auto shadow-sm p-4" style={{ borderTop: '5px solid #DF8E2E' }}>            
            <table data-toggle="table" data-classes="table table-hover table-condensed"
                data-striped="true" data-sort-name="Quality" data-sort-order="desc" id="Tabla"
                className="Tabla table table-hover table-condensed" data-page-length='10'>
                <thead>
                    <tr>
                        <th className="text-center">First Name</th>
                        <th className="text-center">Last Name</th>
                        <th className="text-center">Email</th>
                        <th className="text-center">Feedback & Comments</th>
                        <th data-sortable="true">Date and time</th>
                    </tr>
                </thead>
                <tbody>
                    {feedbacks.map(feedback =>(
                        <tr key={feedback.id}>
                            <td>{feedback.name}</td>
                            <td>{feedback.lastname}</td>
                            <td>{feedback.email}</td>
                            <td className="text-center">
                                <Accordion defaultActiveKey="">
                                    <Accordion.Item eventKey={feedback.id}>
                                    <Accordion.Header>Feedback</Accordion.Header>
                                    <Accordion.Body>
                                    <ul className="list-group list-group-flush ">
                                        <li className="list-group-item bg-transparent m-0 p-1">1. How would you rate overall experience with portal?
                                            <p className="font-weight-bold pb-0 m-0">{ feedback.overall }</p>
                                        </li>
                                        <li className="list-group-item bg-transparent m-0 p-1">2. Overall experience with features?
                                            <p className="font-weight-bold pb-0 m-0">{ feedback.features }</p>
                                        </li>
                                        <li className="list-group-item bg-transparent m-0 p-1">3. Look and feel of the portal?
                                            <p className="font-weight-bold pb-0 m-0 m-0 p-1">{ feedback.lookandfeel }</p>
                                        </li>
                                        <li className="list-group-item bg-transparent m-0 p-1">4. Overall Satisfaction?
                                            <p className="font-weight-bold pb-0 m-0">{ feedback.satisfaction }</p>
                                        </li>
                                        <li className="list-group-item bg-transparent m-0 p-1">5. Overall Support?
                                            <p className="font-weight-bold pb-0 m-0">{ feedback.support }</p>
                                        </li>
                                    </ul>
                                    <div className="col-12 border-top m-0 pl-1 pt-2"> <span className="font-weight-bold">Comments</span>
                                    <p>{ feedback.comments }</p>
                                    </div>
                                    </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </td>
                            <td className="text-center">{feedback.created_at}</td>
                        </tr>
                    ))

                    }
                </tbody>
            </table>
        </div>
    </div>
  )
}

export default Feedbacklist
import React,{useState} from 'react'
import Modal from 'react-bootstrap/Modal';
import AuthUser from "../AuthUser";
function Documentupload(props) {
    const [values,setValues]=useState({title:'',document:''});
    const [errors, setErrors] = useState({title: '',document: '',message:'' });
    const { http, getUser } = AuthUser();

    const [message,setMessage]= useState();
    let userdetails = getUser();
    const validateForm = () => {
        let isValid = true;
        const newErrors = { ...errors };
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        // Validate email
        if (!values.title.trim()) {
            newErrors.title = 'Title is required';
            isValid = false;
        } else {
            newErrors.title = '';
        }

        // Validate password
        if (!values.document.trim()) {
            newErrors.document = 'Document is required';
            isValid = false;
        } else {
            newErrors.password = '';
        }

        setErrors(newErrors);
        setMessage('');
        return isValid;
    };
    function handleChange(e){
        setMessage('');
        setValues({...values,[e.target.name]:e.target.value})
    }
    const handleSubmit = async(e) =>{
        e.preventDefault();
        //validation(values);
        let title = values.title;
        let document = e.target.document.files[0];
        let documenttype = e.target.documenttype.value;
        let ustype=props.usertype === 'attorney' ? 'AT' : 'S';
        const newErrors = { ...errors };
        
        if (validateForm()) {
          http.post(`${props.usertype}/documentAdminupload`,{title:title,document:document,documenttype:documenttype,userid:userdetails.id,user_type:ustype}).then((response)=>{
              //setToken(response.data.userdetail,response.data.token,usertype);
              //console.log(response.status);
              console.log(response.data.message);
              setValues({
                title: '',
                document: '',
              });
              e.target.reset();
              setMessage('FIle uploaded successfully!');
              
          }).catch(function (error) {
            // handle error
            //console.log(error.response.data.error);
            if(error.response.status==403){
              newErrors.title = error.response.data?.error?.title;
              newErrors.document = error.response.data?.error?.document;
              setErrors(newErrors);
            }
            if(error.response.status==401){
              newErrors.message = error.response.data?.error;
              setErrors(newErrors);
            }
            //setsubmitted(false)
          })
        } else {
          // Form is not valid, display error messages
    
        }
    }
  return (
    <>
        <Modal size="md" aria-labelledby="contained-modal-title-vcenter" show={props.show} onHide={props.close} centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Upload Document</Modal.Title>
            </Modal.Header>
            <form method="POST" onSubmit={handleSubmit}>
            <Modal.Body>                
                <div className="input_fields_wrap">
                    <div className="mb-2 col-md-12">
                        <label className="form-label small" style={{ color: '#2D8BB4' }}><strong>Document Title*</strong></label>
                        <input className="form-control title" list="datalistOptions"  name="title" onChange={handleChange} placeholder="Enter Title" />
                        <input type="hidden" className="form-control title" list="datalistOptions"  name="documenttype" value={props.documenttype} />
                        <div className="invalid-feedback small">{errors.title}</div>
                    </div>
                    <div className="mb-2 col-md-12 mt-2">
                        <label className="form-label small" style={{ color: '#2D8BB4' }}><strong>Upload File*</strong></label>
                        <br />
                        <div className="mb-3">
                            <input className="form-control file" type="file" name="document" onChange={handleChange} accept=".docx,application/pdf" id="formFile" />
                            <div className="invalid-feedback small">{errors.document}</div>
                            <div className="invalid-feedback small">{errors.message}</div>
                            <div className="alert-success small">{message}</div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="mb-2 col-md-12 text-center">
                    <button type="button" className="btn btn-secondary ml-2" >Cancel</button> 
                    <button type="submit" onClick={validateForm} className="btn btn-primary ml-2">Submit</button>
                </div>
            </Modal.Footer>
            </form>
        </Modal>
    </>
  )
}

export default Documentupload
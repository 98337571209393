import React from 'react'

function AdminDashboard() {
  return (
    <div className="container-fluid mb-5">
		<div className="col-lg-9 m-auto"><h6 className="mb-2 font-weight-bold pl-2">Dashboard</h6></div>
        <div className="col-lg-9 bg-white mb-3 p-4 m-auto border">                
            <div className="row ">            
                <div className="col-xl-12 col-md-9 mb-4 border-left">
                    <div className="row" style={{ fontWeight:'500'}}>
                        <div className="col-md-4 border-right">                
                            <ul className="list-group ">
                                <a  href="/admin/employers" className="list-group-item d-flex justify-content-between align-items-start border-bottom border-0 list-group-item-action" >
                                    <div className="ms-2 me-auto"> <div className="fw-bold">Employers</div> View and manage employers</div> <span className="badge  rounded-0" style={{ backgroundColor:'#2F9DCC'}} ></span> </a> 
                                <a  href="/admin/attorneys" className="list-group-item d-flex justify-content-between align-items-start border-bottom border-0 list-group-item-action" >
                                    <div className="ms-2 me-auto"> <div className="fw-bold">Attorneys</div> View and manage attorneys</div> <span className="badge  rounded-0" style={{ backgroundColor:'#2F9DCC'}}></span> </a> 
                                <a  href="/admin/applicants" className="list-group-item d-flex justify-content-between align-items-start border-bottom border-0 list-group-item-action" >
                                    <div className="ms-2 me-auto"> <div className="fw-bold">IT Consultants</div> View and manage IT Consultants</div> <span className="badge  rounded-0" style={{ backgroundColor:'#2F9DCC'}}></span> </a> 
                                <a  href="/admin/sampledocuments" className="list-group-item d-flex justify-content-between align-items-start border-bottom border-0 list-group-item-action" >
                                    <div className="ms-2 me-auto"> <div className="fw-bold">Sample Documents</div> View and manage Sample Documents</div> <span className="badge  rounded-0" style={{ backgroundColor:'#2F9DCC'}}></span> </a> 
                                
                                <a  href="/admin/msaDocuments" className="list-group-item d-flex justify-content-between align-items-start border-bottom border-0 list-group-item-action" >
                                    <div className="ms-2 me-auto"> <div className="fw-bold">MSA</div> view and manage MSA documents</div> <span className="badge  rounded-0" style={{ backgroundColor:'#2F9DCC'}}></span> </a> 
                                <a  href="/admin/sowDocuments" className="list-group-item d-flex justify-content-between align-items-start border-bottom border-0 list-group-item-action" >
                                    <div className="ms-2 me-auto"> <div className="fw-bold">SOW</div> View and manage SOW documents</div> <span className="badge  rounded-0" style={{ backgroundColor:'#2F9DCC'}}></span> </a> 
                                <a  href="/admin/publicDocuments" className="list-group-item d-flex justify-content-between align-items-start border-bottom border-0 list-group-item-action" >
                                    <div className="ms-2 me-auto"> <div className="fw-bold">Public access files</div> View and manage public access files</div> <span className="badge  rounded-0" style={{ backgroundColor:'#2F9DCC'}}></span> </a> 
                                <a  href="/admin/publicCompliance" className="list-group-item d-flex justify-content-between align-items-start border-bottom border-0 list-group-item-action" >
                                    <div className="ms-2 me-auto"> <div className="fw-bold">Public access files compliance</div> View and manage public access files compliance</div> <span className="badge  rounded-0" style={{ backgroundColor:'#2F9DCC'}}></span> </a>
                                <a href="/admin/uscisDocuments" className="list-group-item d-flex justify-content-between align-items-start border-bottom border-0 list-group-item-action" >
                                    <div className="ms-2 me-auto"> <div className="fw-bold">USCIS documentation</div> view and manage documents submitted to USCIS</div> <span className="badge  rounded-0" style={{ backgroundColor:'#2F9DCC'}}></span> </a>  
                                    
                            </ul>
                        </div>
                        <div className="col-md-4 border-right">
                            <ul className="list-group">
                                <a href="/admin/uscisprocesstimes" className="list-group-item d-flex justify-content-between align-items-start border-bottom border-0 list-group-item-action" >
                                    <div className="ms-2 me-auto"> <div className="fw-bold">USCIS Case Processing Times</div> View USCIS Case Processing Times</div> <span className="badge  rounded-0" style={{ backgroundColor:'#2F9DCC'}}></span> </a>
                                <a  href="/admin/h1bQuestionaire" className="list-group-item d-flex justify-content-between align-items-start border-bottom border-0 list-group-item-action" >
                                    <div className="ms-2 me-auto"> <div className="fw-bold">H1b Questionaire</div> View and manage H1b Questionaire</div> <span className="badge  rounded-0" style={{ backgroundColor:'#2F9DCC'}}></span> </a> 
                                <a  href="/admin/tickettype" className="list-group-item d-flex justify-content-between align-items-start border-bottom border-0 list-group-item-action" >
                                    <div className="ms-2 me-auto"> <div className="fw-bold">Tickets Type</div> View and mange Tickets Type</div> <span className="badge  rounded-0" style={{ backgroundColor:'#2F9DCC'}}></span> </a>
                                <a  href="/admin/pointofcontact" className="list-group-item d-flex justify-content-between align-items-start border-bottom border-0 list-group-item-action" >
                                    <div className="ms-2 me-auto"> <div className="fw-bold">Point of contact</div> View and manage Point of contact</div> <span className="badge  rounded-0" style={{ backgroundColor:'#2F9DCC'}}></span> </a> 
                                <a  href="/admin/tickets" className="list-group-item d-flex justify-content-between align-items-start border-bottom border-0 list-group-item-action" >
                                    <div className="ms-2 me-auto"> <div className="fw-bold">Tickets</div> view and manage tickets</div> <span className="badge  rounded-0" style={{ backgroundColor:'#2F9DCC'}}></span> </a> 
                                
                                <a  href="/admin/opentickets" className="list-group-item d-flex justify-content-between align-items-start border-bottom border-0 list-group-item-action" >
                                    <div className="ms-2 me-auto"> <div className="fw-bold">Open Tickets</div> view and manage open tickets</div> <span className="badge  rounded-0" style={{ backgroundColor:'#2F9DCC'}}></span> </a> 
                                
                                <a  href="/admin/closedtickets" className="list-group-item d-flex justify-content-between align-items-start border-bottom border-0 list-group-item-action" >
                                    <div className="ms-2 me-auto"> <div className="fw-bold">Closed Tickets</div> view and manage closed tickets</div> <span className="badge  rounded-0" style={{ backgroundColor:'#2F9DCC'}}></span> </a>
                                <a  href="/admin/premiumtickets" className="list-group-item d-flex justify-content-between align-items-start border-bottom border-0 list-group-item-action" >
                                    <div className="ms-2 me-auto"> <div className="fw-bold">Premium processing</div> view and manage premium processing cases</div> <span className="badge  rounded-0" style={{ backgroundColor:'#2F9DCC'}}></span> </a> 
                                
                                <a  href="/admin/Regulartickets" className="list-group-item d-flex justify-content-between align-items-start border-bottom border-0 list-group-item-action" >
                                    <div className="ms-2 me-auto"> <div className="fw-bold">Regular processing</div> view and manage regular processing cases</div> <span className="badge  rounded-0" style={{ backgroundColor:'#2F9DCC'}}></span> </a> 
                            </ul>
                        </div>
                        <div className="col-md-4 border-right">
                            <ul className="list-group">
                                <a href="/admin/newempdocuments" className="list-group-item d-flex justify-content-between align-items-start border-bottom border-left-0 border-right-0 border-top-0  list-group-item-action">
                                    <div className="ms-2 me-auto"><div className="fw-bold">New Employee Documentation</div> View all New Employee Documents</div></a>
                                <a href="/admin/performancereview" className="list-group-item d-flex justify-content-between align-items-start border-bottom border-left-0 border-right-0 border-top-0 list-group-item-action">
                                    <div className="ms-2 me-auto"><div className="fw-bold">Performance Review</div> View Performance Review</div></a>
                                <a href="/admin/timesheet" className="list-group-item d-flex justify-content-between align-items-start border-bottom border-left-0 border-right-0 border-top-0 list-group-item-action">
                                    <div className="ms-2 me-auto"><div className="fw-bold">Timesheets</div> View and manage timesheets</div></a>
                                <a href="/admin/events" className="list-group-item d-flex justify-content-between align-items-start border-bottom border-left-0 border-right-0 border-top-0 list-group-item-action">
                                    <div className="ms-2 me-auto"><div className="fw-bold"> Events</div>View and manage all events</div></a>
                                <a href="/admin/payrollcalendar" className="list-group-item d-flex justify-content-between align-items-start border-bottom border-left-0 border-right-0 border-top-0 list-group-item-action">
                                    <div className="ms-2 me-auto"><div className="fw-bold">Payroll Calendar</div> View Payroll Calendar</div></a>
                                <a href="/admin/paychexlogin" className="list-group-item d-flex justify-content-between align-items-start border-bottom border-left-0 border-right-0 border-top-0 list-group-item-action">
                                    <div className="ms-2 me-auto"><div className="fw-bold">Paychex Login Info</div> Create account in Paychex to view and manage paystub,w2, address and other info.</div></a>
                                {/*<a href="/admin/insurancevendorinfo" className="list-group-item d-flex justify-content-between align-items-start border-bottom border-left-0 border-right-0 border-top-0 list-group-item-action">
                                    <div className="ms-2 me-auto"><div className="fw-bold">Insurance Vendor Info</div> View Insurance Vendor Info</div></a>*/ }
                                <a href="/admin/referralpolicy" className="list-group-item d-flex justify-content-between align-items-start border-bottom border-left-0 border-right-0 border-top-0 list-group-item-action">
                                    <div className="ms-2 me-auto"><div className="fw-bold">Employee Referral Policy</div> View Employee Referral Policy</div></a>
                                <a href="/admin/requests" className="list-group-item d-flex justify-content-between align-items-start border-bottom border-left-0 border-right-0 border-top-0 list-group-item-action">
                                    <div className="ms-2 me-auto"><div className="fw-bold">Requests</div> View all requests</div></a>
                                <a href="/admin/suggestions" className="list-group-item d-flex justify-content-between align-items-start border-bottom border-left-0 border-right-0 border-top-0 list-group-item-action">
                                    <div className="ms-2 me-auto"><div className="fw-bold">Suggestions</div> View and submit suggestions</div></a>
                                <a href="/admin/feedbacks" className="list-group-item d-flex justify-content-between align-items-start border-left-0 border-right-0 border-top-0 list-group-item-action">
                                    <div className="ms-2 me-auto"><div className="fw-bold">Feedback</div> View and submit your feedback</div></a>
                            </ul>
                        </div>                  
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AdminDashboard
import React, { useState, useEffect } from 'react'
import Dropdown from "react-bootstrap/Dropdown";
import AuthUser from "../AuthUser";
import Modal from 'react-bootstrap/Modal';
import Showlca from './Showlca';
import Editlca from './Editlca';
import Documentupload from '../Common/Documentupload';
function Lcalist(props) {
    let usertype = props.logintype;
    let pageview = props.pageview;
    let apiurl = props.apiurl;
    let dashboard=`/${usertype}`;
    const [lcaslist, setLcaslist] = useState([]);
    const [locations,setLocations]=useState([]);
    const [filter, setFilter] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const { http, getUser } = AuthUser();
    const[lcashow,setLcashow]=useState('');
    //Delete Modal
    const [showModal, setShowModal] = useState(false);
    const [selectedid, setSelectedid] = useState(null);
    const [modalshow, setModalshow] = useState(false);
    const [editshow, setEditshow] = useState(false);
    const handleModalShow = (lid) =>{
        setModalshow(true);
        setLcashow(lid);
    }
    const handleEditShow = (lid) =>{
        setEditshow(true);
        setLcashow(lid);
    }
    let userdetails = getUser();
        function fetchItems(){
            http.get(`${usertype}/${apiurl}/${userdetails.email}`)
            .then((response) => {
                console.log(response.data);
                setLcaslist(response.data.lcalist);
                setFilteredData(response.data.lcalist);
                setLocations(response.data.worklocations)
                console.log(response.data.worklocations)
            })
            .catch(function (error) {
                // handle error
                console.log(error.response.data.error);
            });
        }
        useEffect(() => {
            fetchItems();
        }, []);
        const handleFilterChange = (e) => {
            const value = e.target.value.toLowerCase();
            setFilter(value);

            const filtered = lcaslist.filter(item =>
                item.firstname.toLowerCase().includes(value) || item.lastname.toLowerCase().includes(value) || item.middlename.toLowerCase().includes(value) // Add more conditions based on your data structure
            );

            setFilteredData(filtered);
        };
        const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
            <a
              href=""
              ref={ref}
              onClick={e => {
                e.preventDefault();
                onClick(e);
              }}
            >
              {children}
              <span className="threedots" />
            </a>
          ));
        const handleDelete = () =>{
            http.post(`${usertype}/deletelca`,{lcaid:selectedid})
            .then((response) => {
                fetchItems();
                handleCloseModal(); 
            })
            .catch(function (error) {
                // handle error
                console.log(error.response.data.error);
            });
        } 
        const handleShowModal = (id) => {
            setSelectedid(id);
            setShowModal(true);
        };
    
        const handleCloseModal = () => {
            setSelectedid(null);
            setShowModal(false);
        };
  return (
    <div className="container-fluid">
        <form >
        <div className="row mb-2 ">
            <div className="col-md-6">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb p-0">
                        <li className="breadcrumb-item "><a href={dashboard}>Dashboard</a></li>
                        <li className="breadcrumb-item active" aria-current="page">LCA Details</li>
                    </ol>
                </nav>
            </div>
            
            <div className="col-md-6">            
                    <div className="row">
                        <input type="text" name="email" value={filter}
                                onChange={handleFilterChange}  className="form-control col-md-5 rounded-0 searchEmail"
                            placeholder="Search by First name, last name, phone or email id..." />
                        <div className="col-md-2 ml-2">
                            { /*
                            <div className="dropdown">
                                <button className="btn btn-primary dropdown-toggle rounded-0 btn-sm" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"> <i className="bi bi-download"></i> Download </button>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li><button type="submit" className="dropdown-item bi bi-file-earmark-pdf" id="frm-pdf" name="export" value="Pdf">Pdf </button></li>
                                    <li><button type="submit" className="dropdown-item bi bi-file-earmark-word" id="frm-word" name="export" value="Word">Word </button></li>
                                    <li><button type="submit" className="dropdown-item bi bi-file-earmark-spreadsheet" id="frm-excel" name="export" value="Excel">Excel </button></li>
                                </ul>
                            </div>
                            */ }
                        </div>
                        <div className="col-md-2">
                            { usertype=='employer' && <a type="button" href="/employer/addlca"  className="btn btn-primary  rounded-0 btn-sm"><i className="bi bi-plus"></i>Create LCA</a> }
                        </div>
                    </div>

            </div>
        </div>
        <div className="row">
            <div className="col-md-12 mb-4">
                <div className="card  mb-4">
                    <div className="card-body">
                        <div className="">
                            <table data-toggle="table" data-striped="true"
                                data-sort-name="Quality" data-sort-order="desc" className="table table-hover table-condensed" id="Tabla" data-page-length='10'>
                                <thead>
                                    <tr>
                                        <th data-sortable="true">First Name</th>
                                        <th data-sortable="true">Middle Name</th>
                                        <th>Last Name</th>
                                        <th>Job Title</th>
                                        <th>Work Location</th>
                                        <th data-sortable="true">Wage Level</th>
                                        <th>Job Duties</th>
                                        <th data-sortable="true">Employee Count</th>
                                        <th data-sortable="true">Created Date</th>
                                        { usertype=='employer' && <th>Action</th> }
                                    </tr>
                                </thead>
                                <tbody>
                                {filteredData?.map(lca => (
                                
                                    <tr key={lca.id}>
                                        <td>{lca.firstname}</td>
                                        <td>{lca.middlename}</td>
                                        <td>{lca.lastname}</td>
                                        <td>{lca.jobtitle}</td>
                                        <td>{ locations.hasOwnProperty(lca.id) ? locations[lca.id].work_location: '' }</td>
                                        <td>{lca.wagelevel}</td>
                                        <td>{lca.jobduties}</td>
                                        <td>{lca.empcount}</td>
                                        <td>{lca.created_at}</td>
                                        { usertype=='employer' &&
                                        <td>
                                            <Dropdown>
                                                <Dropdown.Toggle as={CustomToggle} />
                                                <Dropdown.Menu size="sm" title="">                                                            
                                                        <Dropdown.Item href="#" onClick={() => handleModalShow(lca.id)} >View</Dropdown.Item>
                                                        <Dropdown.Item href="#" onClick={() => handleEditShow(lca.id)}>Edit</Dropdown.Item>
                                                        <Dropdown.Item href="#" onClick={()=>handleShowModal(lca.id)}>Delete</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td>
                                        }
                                    </tr>
                                ))}

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </form> {/* https://github.com/bradtraversy/expense-tracker-react/blob/master/src/App.js */}
        <Modal size="md" aria-labelledby="contained-modal-title-vcenter" show={showModal} onHide={handleCloseModal} centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Delete</Modal.Title>
            </Modal.Header>
            <Modal.Body>                
                <div className="input_fields_wrap">
                    <div className="mb-2 col-md-12">
                        <p style={{ color: '#2D8BB4' }}><strong>Are you sure you want to delete ?</strong></p>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="mb-2 col-md-12 text-center">
                    <button type="button" className="btn btn-secondary ml-2" onClick={handleCloseModal}>No</button> 
                    <button type="submit" onClick={handleDelete} className="btn btn-primary ml-2">Yes</button>
                </div>
            </Modal.Footer>
        </Modal>
        <Showlca show={modalshow} id={lcashow} usertype={usertype} close={() => setModalshow(false)} />
        <Editlca show={editshow} id={editshow === true ? lcashow : ''} usertype={usertype} close={() => { setEditshow(false); fetchItems();} }/>
    </div>
  )
}

export default Lcalist
import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import AuthUser from "../AuthUser";
import Doc from '../images/doc.png';
import Pdf from '../images/pdf.png';
function Withdrawdocuments() {
    const[loader,setLoader]=useState(false);
    const [show, setShow] = useState(false);
    const[message,setMessage]=useState();
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
    const [allChecked, setAllChecked] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [withdrawdocs, setWithdrawdocs] = useState([]);
    const [filter, setFilter] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [values,setValues]=useState({firstname:'',lastname:'',title:'',document:''});
    const [errors, setErrors] = useState({firstname:'',lastname:'',title:'',document:'',message:'' });
    const { http, getUser } = AuthUser();
    let userdetails = getUser();
    function fetchItems(){
        http.get(`employer/withdrawdocuments/${userdetails.email}`)
        .then((response) => {
            setWithdrawdocs(response.data.withdrawdocs);
            setFilteredData(response.data.withdrawdocs);
        })
        .catch(function (error) {
            // handle error
            console.log(error.response.data.error);
        });
    }
    useEffect(() => {
        fetchItems();
    }, []);
    const handleFilterChange = (e) => {
        const value = e.target.value.toLowerCase();
        setFilter(value);

        const filtered = withdrawdocs.filter(item =>
            item.firstname.toLowerCase().includes(value) || item.lastname.toLowerCase().includes(value) || item.title.toLowerCase().includes(value)
            // Add more conditions based on your data structure
        );

        setFilteredData(filtered);
    };
    const handleAllCheckboxChange = () => {
        setAllChecked(!allChecked);
        setSelectedCheckboxes(filteredData.map(li => li.documents));
        if (allChecked) {
            setSelectedCheckboxes([]);
        }
    };
    const handleCheckboxChange = (value) => {
        // Update the selected checkboxes array when a checkbox is checked or unchecked
        setSelectedCheckboxes((prevSelected) =>
          prevSelected.includes(value)
            ? prevSelected.filter((item) => item !== value)
            : [...prevSelected, value]
        );
    };
    const handleDownload = () =>{
        setLoader(true)
        http.post(`employer/downloadFiles`,{files:selectedCheckboxes}, {
            responseType: 'blob',
        })
        .then((response) => {
            //console.log(response);
            let filename = (selectedCheckboxes.length === 1) ? selectedCheckboxes : 'download.zip';
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url; //''
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setLoader(false)
        })
        .catch(function (error) {
            // handle error
            console.log(error.response.data.error);
        });
    }
    const validateForm = () => {
        let isValid = true;
        const newErrors = { ...errors };
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!values.firstname.trim()) {
            newErrors.firstname = 'First name is required';
            isValid = false;
        } else {
            newErrors.firstname = '';
        }
        if (!values.lastname.trim()) {
            newErrors.lastname = 'Last name is required';
            isValid = false;
        } else {
            newErrors.lastname = '';
        }
        // Validate title
        if (!values.title.trim()) {
            newErrors.title = 'Title is required';
            isValid = false;
        } else {
            newErrors.title = '';
        }

        // Validate password
        if (!values.document.trim()) {
            newErrors.document = 'Document is required';
            isValid = false;
        } else {
            newErrors.document = '';
        }

        setErrors(newErrors);
        return isValid;
    };
      
    function handleChange(e){
        setValues({...values,[e.target.name]:e.target.value})
    }
    const handleSubmit = async(e) =>{
        e.preventDefault();
        //validation(values);
        let firstname = values.firstname;
        let lastname = values.lastname;
        let title = values.title;
        let document = e.target.document.files[0];
        let documenttype = 'WITHDRAW';
        let ustype='S';
        const newErrors = { ...errors };
        
        if (validateForm()) {
          http.post(`employer/documentAdminupload`,{firstname:firstname,lastname:lastname,title:title,document:document,documenttype:documenttype,user_type:ustype}).then((response)=>{
              //setToken(response.data.userdetail,response.data.token,usertype);
              //console.log(response.status);
              setMessage('FIle uploaded successfully!');
              e.target.reset();
              fetchItems();
              console.log(response.data.message);
              
          }).catch(function (error) {
            // handle error
            //console.log(error.response.data.error);
            if(error.response.status==403){
              newErrors.title = error.response.data?.error?.title;
              newErrors.document = error.response.data?.error?.document;
              setErrors(newErrors);
            }
            if(error.response.status==401){
              newErrors.message = error.response.data?.error;
              setErrors(newErrors);
            }
            //setsubmitted(false)
          })
        } else {
          // Form is not valid, display error messages
    
        }
    }
  return (
    <div className="container-fluid">
        <div className="row mb-2 ">
            <div className="col-md-4">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb p-0">
                        <li className="breadcrumb-item "><a href="/employer">Dashboard</a></li>
                        <li className="breadcrumb-item active" aria-current="page">Cancellations / Withdrawals Documents</li>
                    </ol>
                </nav>
            </div>
            <div className="col-md-4 small  text-right">
                <div className="row">
                    <input type="text" name="email" className="form-control searchEmail" placeholder="Search by title" value={filter} onChange={handleFilterChange}/>                    
                </div>
            </div>
            <div className="col-md-4 text-right">
                <a href="#" data-toggle="modal" onClick={handleShow} className="text-primary pr-3"> <i className="bi bi-plus"></i> Add Documents</a> 
            </div>
        </div> 
            <div className="row">
                <div className="col-md-6">&nbsp;</div>
                <div className="col-md-6 text-right">
                    <input type="hidden" name="user_id" value="{{ Request::segment(3) }}" />
                    <input type="hidden" className="form-control title" list="datalistOptions" id="exampleDataList" name="document_type" value="WITHDRAW" />
                    <button type="submit" className="btn btn-primary ml-2 mb-2" id="frm-download" name="merge" onClick={handleDownload}
                        value="download">Download </button>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 pb-5">
                    <div className="card  mb-4">
                        <div className="card-body">
                            
                            <div className="table-responsive">
                                <table data-toggle="table" data-classes="table table-hover table-condensed"
                                    data-striped="true" data-sort-name="Quality" data-sort-order="desc" id="Tabla"
                                    className="table table-hover table-condensed table-striped dataTable no-footer" data-page-length='10'>
                                    <thead>
                                        <tr>
                                            <th className="text-center">
                                                <input name="select_all" id="example-select-all" type="checkbox" checked={allChecked} onChange={handleAllCheckboxChange}/>
                                            </th>
                                            <th data-sortable="true">First Name</th>
                                            <th data-sortable="true">Last Name</th>
                                            <th data-sortable="true">Document Title</th>
                                            <th data-sortable="true"><i className="bi bi-paperclip"></i></th>
                                            <th data-sortable="true">Document Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {filteredData.map(withdrawDoc => (
                                        
                                        <tr key={withdrawDoc.id}>
                                            <td className="text-center"><input type="checkbox" name="allmerge[]" checked={selectedCheckboxes.includes(withdrawDoc.documents)}  onChange={() => handleCheckboxChange(withdrawDoc.documents)} value={withdrawDoc.documents} /></td>
                                            <td>{withdrawDoc.firstname}</td>
                                            <td>{withdrawDoc.lastname}</td>
                                            <td>{withdrawDoc.title}</td>
                                            <td><img src={withdrawDoc.documents.split('.').pop() == 'pdf' ? Pdf : Doc} />{withdrawDoc.title}</td>
                                            <td>{withdrawDoc.created_at}&nbsp;{}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <Modal size="md" aria-labelledby="contained-modal-title-vcenter" show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Upload Document</Modal.Title>
            </Modal.Header>
            <form method="POST" onSubmit={handleSubmit}>
            <Modal.Body>                
                <div className="input_fields_wrap">
                    <div className="mb-2 col-md-12">
                        <label className="form-label small" style={{ color: '#2D8BB4' }}><strong>First Name*</strong></label>
                        <input className="form-control title" list="datalistOptions"  name="firstname" onChange={handleChange} placeholder="Enter First Name" />
                        <div className="invalid-feedback small">{errors.firstname}</div>
                    </div>
                    <div className="mb-2 col-md-12">
                        <label className="form-label small" style={{ color: '#2D8BB4' }}><strong>Last Name*</strong></label>
                        <input className="form-control title" list="datalistOptions"  name="lastname" onChange={handleChange} placeholder="Enter Last Name" />
                        <div className="invalid-feedback small">{errors.lastname}</div>
                    </div>
                    <div className="mb-2 col-md-12">
                        <label className="form-label small" style={{ color: '#2D8BB4' }}><strong>Document Title*</strong></label>
                        <input className="form-control title" list="datalistOptions"  name="title" onChange={handleChange} placeholder="Enter Title" />
                        <input type="hidden" className="form-control title" list="datalistOptions"  name="documenttype" value="WITHDRAW" />
                        <div className="invalid-feedback small">{errors.title}</div>
                    </div>
                    <div className="mb-2 col-md-12 mt-2">
                        <label className="form-label small" style={{ color: '#2D8BB4' }}><strong>Upload File*</strong></label>
                        <br />
                        <div className="mb-3">
                            <input className="form-control file" type="file" name="document" onChange={handleChange} accept=".docx,application/pdf" id="formFile" />
                            <div className="invalid-feedback small">{errors.document}</div>
                            <div className="invalid-feedback small">{errors.message}</div>
                            <div className="alert-success small">{message}</div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="mb-2 col-md-12 text-center">
                    <button type="button" className="btn btn-secondary ml-2" >Cancel</button> 
                    <button type="submit" onClick={validateForm} className="btn btn-primary ml-2">Submit</button>
                </div>
            </Modal.Footer>
            </form>
        </Modal>
    </div>
  )
}

export default Withdrawdocuments
import React,{useState,useEffect} from 'react'
import Modal from 'react-bootstrap/Modal';
import AuthUser from "../AuthUser";

function Timesheetdetails(props) {
    const [months,setMonths]=useState([
        {
            monthno: '01',
            monthname: "Jan"
        },
        {
            monthno: '02',
            monthname: "Feb"
        },
        {
            monthno: '03',
            monthname: "Mar"
        },
        {
            monthno: '04',
            monthname: "Apr"
        },
        {
            monthno: '05',
            monthname: "May"
        },
        {
            monthno: '06',
            monthname: "Jun"
        },
        {
            monthno: '07',
            monthname: "Jul"
        },
        {
            monthno: '08',
            monthname: "Aug"
        },
        {
            monthno: '09',
            monthname: "Sep"
        },
        {
            monthno: '10',
            monthname: "Oct"
        },
        {
            monthno: '11',
            monthname: "Nov"
        },
        {
            monthno: '12',
            monthname: "Dec"
        }]);
        //Date 
        const now = new Date();
        const current = now.getFullYear();
        const month= now.getMonth();
        const previous = current - 1;
        const { http, getUser } = AuthUser();
        let usertype=props.logintype;
        let userdetails = getUser();
    let dashboard=`/${usertype}`;
        const[monthcount,setMonthcount]=useState([]);
        const[firstqtr,setFirstqtr]=useState([]);
        const[secondqtr,setSecondqtr]=useState([]);
        const[thirdqtr,setThirdqtr]=useState([]);
        const[fourthqtr,setFourthqtr]=useState([]);
        function fetchItems(){
            var url = `${usertype}/timesheetdetails`;
            http.get(url)
            .then((response) => {
                console.log(response.data);
                setMonthcount(response.data.monthcount);
                setFirstqtr(response.data.firstqtr);
                setSecondqtr(response.data.secondqtr);
                setThirdqtr(response.data.thirdqtr);                
                setFourthqtr(response.data.fourthqtr);
            })
            .catch(function (error) {
                // handle error
                console.log(error.response.data.error);
            });
        }
        useEffect(() => {
            fetchItems();
            
        }, []);
  return (
    <div className="container-fluid">
        <div className="col-md-9  m-auto p-0 pb-2 ">
            <div className='row'>
                <div className="col-md-6 text-left pull-left">
                    <h6 className="mb-2 font-weight-bold">Timesheets</h6>
                </div>
                <div className="col-md-6 text-right pull-right">
                    <a href={dashboard} className="mb-2 font-weight-bold">Back</a>           
                </div>
            </div>
        </div>
        <div className="col-md-9 bg-white m-auto shadow-sm p-4" style={{ borderTop: '5px solid #DF8E2E' }}>
            <div className="row mt-3 mb-3 mr-3">
                <div className="col-md-9 m-auto bg-white p-4 rounded">
                    <table width="100%" >
                        <thead>
                            
                            <tr>
                            {
                                months.map(month => (
                                    <th key={month.monthno}><a href={`/${usertype}/timesheetreport/${month.monthno}`}>{month.monthname}</a> {monthcount[month.monthname]}</th>
                                ))
                            }
                            </tr>
                        </thead>
                    </table>
                    <ul className="list-group mt-4 border-0">
                        <li className="list-group-item">
                            <a href={`/${usertype}/timesheetreport/01,02,03`} className="font-weight-bold">Quarter 1</a>
                                <span className="small">( Jan, Feb, Mar )</span>                                
                                <span className="font-weight-bold"> {firstqtr}</span>
                        </li>
                        <li className="list-group-item">
                            <a href={`/${usertype}/timesheetreport/04,05,06`} className="font-weight-bold ">Quarter 2</a>
                            <span className="small">( Apr, May, Jun )</span>
                            <span
                                className="font-weight-bold">{secondqtr}</span>
                        </li>
                        <li className="list-group-item">
                            <a href={`/${usertype}/timesheetreport/07,08,09`} className="font-weight-bold ">Quarter 3</a>
                            <span className="small">( Jul, Aug, Sep )</span>
                            <span
                                className="font-weight-bold">{thirdqtr}</span>
                        </li>
                        <li className="list-group-item">
                            <a href={`/${usertype}/timesheetreport/10,11,12`} className="font-weight-bold ">Quarter 4</a>
                            <span className="small">( Oct, Nov, Dec )</span>
                            <span
                                className="font-weight-bold">{fourthqtr}</span>
                        </li>
                        <li className="list-group-item">
                            <a href={`/${usertype}/timesheetreport/${current}`} className="font-weight-bold ">Year to  Date</a>
                            <span
                                className="font-weight-bold"></span>
                        </li>
                        <li className="list-group-item">
                            <p className="font-weight-bold">Historical data</p>
                            <a href={`/${usertype}/timesheetreport/${previous}`} className=" pl-3">{previous}</a>
                            <a href={`/${usertype}/timesheetreport/${current}`} className=" pl-3">{current}</a>
                        </li>
                    </ul>
                </div>
            </div>            
        </div>
    </div>
  )
}

export default Timesheetdetails
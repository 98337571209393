import React,{useState,useEffect} from 'react'
import Doc from '../images/doc.png'
import Modal from 'react-bootstrap/Modal';
import AuthUser from "../AuthUser";
import Dropdown from "react-bootstrap/Dropdown";
import Viewperformancefile from './Viewperformancefile';
function Viewperformancereview() {
    const { http, getUser } = AuthUser();
    let userdetails = getUser();
    const [viewprerformance, setViewprerformance] = useState([]);
    const [viewprerformance1, setViewprerformance1] = useState([]);
    const now = new Date();
    const current = now.getFullYear();
    const previous = current - 1;
    const [file, setFile] = useState(false);
    const [modalshow, setModalshow] = useState(false);
    const handleModalShow = (filename) =>{
        setModalshow(true);
        setFile(filename);
    } 
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a
            href=""
            ref={ref}
            onClick={e => {
            e.preventDefault();
            onClick(e);
            }}
        >
            {children}
            <span className="threedots" />
        </a>
        ));
    useEffect(() => {
        http.get(`applicant/viewperformancereview/${userdetails.email}`)
          .then((response) => {
            console.log(response.data);
            setViewprerformance(response.data.previousreview);
            setViewprerformance1(response.data.performancereview);
          })
          .catch(function (error) {
            // handle error
            console.log(error.response.data.error);
          });
      }, []);
    const handleDownload = (quarter) =>{
        //console.log(filename);
        http.post(`applicant/performancedownload/${userdetails.email}`,{report:quarter}, {
            responseType: 'blob',
        })
        .then((response) => {
            //console.log(response);
            //let filename = filename;
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url; //''
            link.setAttribute('download', quarter);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        })
        .catch(function (error) {
            // handle error
            console.log('There was an error downloading the file!', error);
        });
    }
  return (
    <div className="container-fluid">
        <div className="col-md-6 m-auto p-0 pb-2 ">
            <h6 className="mb-2 font-weight-bold">View Performance Review</h6>
        </div>
        <div className="col-md-6 bg-white m-auto shadow-sm p-4 text-center" style={{ borderTop: '5px solid #DF8E2E' }}>
            <table data-toggle="table" data-classes="table table-hover table-condensed" data-striped="true" data-sort-name="Quality" data-sort-order="desc" id="Tabla" className="Tabla table table-hover table-condensed" data-page-length='10'>
                <thead>
                    <tr>
                        <th data-sortable="true">Period</th>
                        <th data-sortable="true">Year</th>
                        <th data-sortable="true"><i className="bi bi-paperclip"></i></th>
                        <th data-sortable="true">Submitted Date and Time</th>
                    </tr>
                </thead>
                <tbody>
                <tr>
                    <td>Q1 (Jan to Mar) </td>
                    <td>{ previous }</td>
                    <td>{(viewprerformance != null && viewprerformance.Q1 !== 'NULL') ? 
                            <Dropdown>
                                <Dropdown.Toggle as={CustomToggle} />
                                <Dropdown.Menu size="sm" title="">
                                    <Dropdown.Item href="javascript:void(0);" onClick={() => handleModalShow(viewprerformance.Q1)}>View</Dropdown.Item> 
                                    <Dropdown.Item href="javascript:void(0);" onClick={() => handleDownload(viewprerformance.Q1)} >Download</Dropdown.Item>                                                                    
                                </Dropdown.Menu>
                            </Dropdown> 
                            : 
                            <span className="badge rounded-pill p-1 text-danger " style={{ border: '2px solid #E61414' }}>
                                <span className="blink">
                                    Overdue
                                </span>
                            </span>
                            }
                    </td>
                    <td>{(viewprerformance != null && viewprerformance.Q1 !== 'NULL') ? viewprerformance.Q1submited : ''}</td>
                </tr>
                <tr>
                    <td>Q2 (Apr to Jun)</td>
                    <td>{ previous }</td>
                    <td> {(viewprerformance != null && viewprerformance.Q2 !== 'NULL') ? 
                            <Dropdown>
                                <Dropdown.Toggle as={CustomToggle} />
                                <Dropdown.Menu size="sm" title="">
                                    <Dropdown.Item href="javascript:void(0);" onClick={() => handleModalShow(viewprerformance.Q2)}>View</Dropdown.Item> 
                                    <Dropdown.Item href="javascript:void(0);" onClick={() => handleDownload(viewprerformance.Q2)} >Download</Dropdown.Item>                                                                    
                                </Dropdown.Menu>
                            </Dropdown> 
                            : 
                            <span className="badge rounded-pill p-1 text-danger " style={{ border: '2px solid #E61414' }}>
                                <span className="blink">
                                    Overdue
                                </span>
                            </span>
                            }
                        
                    </td>
                    <td>{(viewprerformance != null && viewprerformance.Q2 !== 'NULL') ? viewprerformance.Q2submited : ''}</td>
                </tr>
                <tr>
                    <td>Q3 (Jul to Sep)</td>
                    <td>{ previous }</td>
                    <td>
                     
                    {(viewprerformance != null && viewprerformance.Q3 !== 'NULL') ? 
                            <Dropdown>
                                <Dropdown.Toggle as={CustomToggle} />
                                <Dropdown.Menu size="sm" title="">
                                    <Dropdown.Item href="javascript:void(0);" onClick={() => handleModalShow(viewprerformance.Q3)}>View</Dropdown.Item> 
                                    <Dropdown.Item href="javascript:void(0);" onClick={() => handleDownload(viewprerformance.Q3)}>Download</Dropdown.Item>                                                                    
                                </Dropdown.Menu>
                            </Dropdown> 
                            : 
                            <span className="badge rounded-pill p-1 text-danger " style={{ border: '2px solid #E61414' }}>
                                <span className="blink">
                                    Overdue
                                </span>
                            </span>
                            }
                    </td>
                    <td>{(viewprerformance != null && viewprerformance.Q3 !== 'NULL') ? viewprerformance.Q3submited : ''}</td>
                </tr>
                <tr>
                    <td>Q4 (Oct to Dec)</td>
                    <td>{ previous }</td>
                    <td> 
                        {(viewprerformance != null && viewprerformance.Q4 !== 'NULL') ? 
                            <Dropdown>
                                <Dropdown.Toggle as={CustomToggle} />
                                <Dropdown.Menu size="sm" title="">
                                    <Dropdown.Item href="javascript:void(0);" onClick={() => handleModalShow(viewprerformance.Q4)}>View</Dropdown.Item> 
                                    <Dropdown.Item href="javascript:void(0);" onClick={() => handleDownload(viewprerformance.Q4)} >Download</Dropdown.Item>                                                                    
                                </Dropdown.Menu>
                            </Dropdown> 
                            : 
                            <span className="badge rounded-pill p-1 text-danger " style={{ border: '2px solid #E61414' }}>
                                <span className="blink">
                                    Overdue
                                </span>
                            </span>
                        }
                    </td>
                    <td>{(viewprerformance != null && viewprerformance.Q4 !== 'NULL') ? viewprerformance.Q4submited : ''}</td>
                </tr>
                <tr >
                    <td>Q1 (Jan to Mar)</td>
                    <td>{ current }</td>
                    <td> 
                        {(viewprerformance1 != null && viewprerformance1.Q1 !== 'NULL') ? 
                        <Dropdown>
                            <Dropdown.Toggle as={CustomToggle} />
                            <Dropdown.Menu size="sm" title="">
                                <Dropdown.Item href="javascript:void(0);" onClick={() => handleModalShow(viewprerformance1.Q1)}>View</Dropdown.Item> 
                                <Dropdown.Item href="javascript:void(0);" onClick={() => handleDownload(viewprerformance1.Q1)} >Download</Dropdown.Item>                                                                    
                            </Dropdown.Menu>
                        </Dropdown> 
                        : 
                        <span className="badge rounded-pill p-1 text-danger " style={{ border: '2px solid #E61414' }}>
                            <span className="blink">
                                Overdue
                            </span>
                        </span>
                        }
                    </td>
                    <td>{(viewprerformance1 != null && viewprerformance1.Q1 !== 'NULL') ? viewprerformance1.Q1submited : ''}</td>
                </tr>
                <tr >
                    <td>Q2 (Apr to Jun)</td>
                    <td>{ current }</td>
                    <td> 
                    {(viewprerformance1 != null && viewprerformance1.Q2 !== 'NULL') ? 
                        <Dropdown>
                            <Dropdown.Toggle as={CustomToggle} />
                            <Dropdown.Menu size="sm" title="">
                                <Dropdown.Item href="javascript:void(0);" onClick={() => handleModalShow(viewprerformance1.Q2)}>View</Dropdown.Item> 
                                <Dropdown.Item href="javascript:void(0);" onClick={() => handleDownload(viewprerformance1.Q2)} >Download</Dropdown.Item>                                                                    
                            </Dropdown.Menu>
                        </Dropdown> 
                        : 
                        <span className="badge rounded-pill p-1 text-danger " style={{ border: '2px solid #E61414' }}>
                            <span className="blink">
                                Overdue
                            </span>
                        </span>
                        }
                    </td>
                    <td>{(viewprerformance1 != null && viewprerformance1.Q2 !== 'NULL') ? viewprerformance1.Q2submited : ''}</td>
                </tr>
                <tr >
                    <td>Q3 (Jul to Sep)</td>
                    <td>{ current }</td>
                    <td>
                     
                    {(viewprerformance1 != null && viewprerformance1.Q3 !== 'NULL') ? 
                        <Dropdown>
                            <Dropdown.Toggle as={CustomToggle} />
                            <Dropdown.Menu size="sm" title="">
                                <Dropdown.Item href="javascript:void(0);" onClick={() => handleModalShow(viewprerformance1.Q3)}>View</Dropdown.Item> 
                                <Dropdown.Item href="javascript:void(0);" onClick={() => handleDownload(viewprerformance1.Q3)} >Download</Dropdown.Item>                                                                    
                            </Dropdown.Menu>
                        </Dropdown> 
                        : 
                        <span className="badge rounded-pill p-1 text-danger " style={{ border: '2px solid #E61414' }}>
                            <span className="blink">
                                Overdue
                            </span>
                        </span>
                        }
                    </td>
                    <td>{(viewprerformance1 != null && viewprerformance1.Q3 !== 'NULL') ? viewprerformance1.Q3submited : ''}</td>
                </tr>
                <tr >
                    <td>Q4 (Oct to Dec)</td>
                    <td>{ current }</td>
                    <td>
                     
                    {(viewprerformance1 != null && viewprerformance1.Q4 !== 'NULL') ?                         
                        <Dropdown>
                            <Dropdown.Toggle as={CustomToggle} />
                            <Dropdown.Menu size="sm" title="">
                                <Dropdown.Item href="javascript:void(0);" onClick={() => handleModalShow(viewprerformance1.Q4)}>View</Dropdown.Item> 
                                <Dropdown.Item href="javascript:void(0);" onClick={() => handleDownload(viewprerformance1.Q4)} >Download</Dropdown.Item>                                                                    
                            </Dropdown.Menu>
                        </Dropdown> 
                        : 
                        <span className="badge rounded-pill p-1 text-danger " style={{ border: '2px solid #E61414' }}>
                            <span className="blink">
                                Overdue
                            </span>
                        </span>
                        }
                    </td>
                    <td>{(viewprerformance1 != null && viewprerformance1.Q4 !== 'NULL') ? viewprerformance1.Q4submited : ''}</td>
                </tr>
                </tbody>
            </table>
        </div>
        <Viewperformancefile show={modalshow} filename={file} foldertype="empselfperformance" close={() => setModalshow(false)} />
    </div>
  )
}

export default Viewperformancereview
import React,{useState,useEffect} from 'react'
import Modal from 'react-bootstrap/Modal';
import AuthUser from "../AuthUser";
function Myreportingmanager() {
    const [values,setValues]=useState({clientname:'',firstname:'',lastname:'',telephone:'',mobileno:'',email:''});
    const [errors, setErrors] = useState({clientname:'',firstname:'',lastname:'',telephone:'',mobileno:'',email:''});
    const [message,setMessage]= useState();
    const [clientmanager, setClientmanager] = useState([]);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const { http, getUser } = AuthUser();
    let userdetails = getUser();
    let usertype = 'applicant';
    let dashboard=`/${usertype}`;
    const validateForm = () => {
        let isValid = true;
        const newErrors = { ...errors };
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const alphaRegex = /^[A-Za-z]+$/;
        const numRegex = /^[0-9\b]+$/;
        // Subject
        if (!values.clientname.trim()) {
            newErrors.clientname = 'Client name is required';
            isValid = false;
        } else if(!alphaRegex.test(values.clientname)){
            newErrors.clientname='Only Characters allowed';
            isValid = false;
        }else {
            newErrors.clientname = '';
        }
        // Message
        if (!values.firstname.trim()) {
            newErrors.firstname = 'First name is required';
            isValid = false;
        } else if(!alphaRegex.test(values.firstname)){
            newErrors.firstname = 'Only Characters allowed';
            isValid = false;
        }else {
            newErrors.firstname = '';
        }
        // File Upload
        if (!values.lastname.trim()) {
            newErrors.lastname = 'Last name is required';
            isValid = false;
        } else if(!alphaRegex.test(values.lastname)){
            newErrors.lastname = 'Only Characters allowed';
            isValid = false;
        }else {
            newErrors.lastname = '';
        }
        if (!values.telephone.trim()) {
            newErrors.telephone = 'Telephone is required';
            isValid = false;
        } else if(!numRegex.test(values.telephone)){
            newErrors.telephone='Only numbers allowed';
            isValid = false;
        }else {
            newErrors.telephone = '';
        }
        if (!values.mobileno.trim()) {
            newErrors.mobileno = 'Mobile no is required';
            isValid = false;
        } else if(!numRegex.test(values.mobileno)){
            newErrors.mobileno='Only numbers allowed';
            isValid = false;
        }else {
            newErrors.mobileno = '';
        }
        if (!values.email.trim()) {
            newErrors.email = 'Email is required';
            isValid = false;
        } else  if(!emailRegex.test(values.email)){
            newErrors.email = 'Email not valid';
            isValid = false;
        }else {
            newErrors.email = '';
        }
        setErrors(newErrors);
        setMessage('');
        return isValid;
    };
    function handleChange(e){
        setMessage('');
        setValues({...values,[e.target.name]:e.target.value});
    }
    const handleSubmit = async(e) =>{
        e.preventDefault();
        let clientname = values.clientname;
        let firstname = values.firstname;
        let lastname = values.lastname;
        let telephone = values.telephone;
        let mobileno = values.mobileno;
        let email = values.email;
        let message = '';
        const newErrors = { ...errors };
        
        if (validateForm()) {
          http.post(`applicant/addclientmanager/${userdetails.email}`,{clientname:clientname,firstname:firstname,lastname:lastname,telephone:telephone,mobileno:mobileno,email:email,userid:userdetails.id}).then((response)=>{
              //setToken(response.data.userdetail,response.data.token,usertype);
              //console.log(response.status);
              console.log(response.data.message);
              setValues({
                clientname: '',
                firstname: '',
                lastname: '',
                telephone: '',
                mobileno: '',
                email: '',
                message: '',
              });
              e.target.reset();
              setMessage('Client manager added successfully!');
              fetchItems();
              
          }).catch(function (error) {
            // handle error
            //console.log(error.response.data.error);
            if(error.response.status==403){
                newErrors.clientname = error.response.data?.error?.clientname;
                newErrors.firstname = error.response.data?.error?.firstname;
                newErrors.lastname = error.response.data?.error?.lastname;
                newErrors.telephone = error.response.data?.error?.telephone;
                newErrors.mobileno = error.response.data?.error?.mobileno;
                newErrors.email = error.response.data?.error?.email;
                setErrors(newErrors);
            }
            if(error.response.status==401){
                newErrors.message = error.response.data?.error;
                setErrors(newErrors);
            }
            //setsubmitted(false)
          })
        } else {
          // Form is not valid, display error messages
    
        }
    }
    const fetchItems = async () => {
        http.get(`applicant/reportingmanager/${userdetails.email}`)
        .then((response) => {
        //console.log(response.data);
        setClientmanager(response.data.clientmanagers);
        })
        .catch(function (error) {
        // handle error
        console.log(error.response.data.error);
        });
      };
    useEffect(() => {
        fetchItems();
    }, []);
  return (
    <>
        <div className="container-fluid">
            
            <div className="col-md-9 m-auto p-0 pb-2 ">
                <div className='row'>
                    <div className="col-md-6 text-left pull-left">
                        <h6 className="mb-2 font-weight-bold">Transnational Reporting Manager</h6>
                    </div>
                    <div className="col-md-6 text-right pull-right">
                        <a href={dashboard} className="mb-2 font-weight-bold">Back</a>           
                    </div>
                </div>
            </div>
            <div className="col-md-9 bg-white m-auto shadow-sm p-4 text-center" style={{ borderTop: '5px solid #DF8E2E' }}>
                <table data-toggle="table" data-classes="table table-hover table-condensed" data-striped="true" data-sort-name="Quality" data-sort-order="desc" id="Tabla" className="Tabla table table-hover table-condensed" data-page-length='10'>
                    <thead>
                        <tr>
                            <th data-sortable="true">First Name</th>
                            <th data-sortable="true">Last Name</th>
                            <th data-sortable="true">Email</th>
                            <th data-sortable="true">Mobile#</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Vijay Krishna</td>
                            <td>Kolla</td>
                            <td>vijay.k@tnsservices.com</td>
                            <td>+1-940-303-9299</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div className="container-fluid mt-4">
            <div className="col-md-9 m-auto p-0 pb-2 mt-4">
                <h6 className="mb-2 font-weight-bold">Client Reporting Manager</h6>
            </div>
            <div className="col-md-9 bg-white m-auto shadow-sm p-4 text-center" style={{ borderTop: '5px solid #DF8E2E' }}>
                <div className='row'>
                    <div className="col-md-6 text-right pull-right">
                        &nbsp;
                    </div>
                    <div className="col-md-6 text-right pull-right">
                        <a href="#" onClick={handleShow} className="btn btn-primary rounded-0"> <i className="bi bi-plus"></i> Add New</a>
                    </div>
                </div>
                <table data-toggle="table" data-classes="table table-hover table-condensed" data-striped="true" data-sort-name="Quality" data-sort-order="desc" id="Tabla" className="Tabla table table-hover table-condensed" data-page-length='10'>
                    <thead>
                        <tr>
                            <th data-sortable="true">Client Name</th>
                            <th className="text-center">Manager Name</th>
                            <th className="text-center">Email</th>
                            <th className="text-center">Tel#</th>
                            <th className="text-center">Mobile#</th>
                        </tr>
                    </thead>
                    <tbody>
                        {clientmanager.map(reporting => (
                        <tr key={reporting.id}>
                            <td className="text-center">{ reporting.clientname}</td>
                            <td>{ reporting.firstname} { reporting.lastname}</td>
                            <td>{ reporting.email}</td>
                            <td>{ reporting.telephone}</td>
                            <td>{ reporting.mobileno}</td>
                        </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
        <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter"><span className="pl-2"> Client Reporting Manager </span></Modal.Title>
            </Modal.Header>
            <form method="POST" onSubmit={handleSubmit}>
            <Modal.Body className='modal-body'>                
                <div className="col-md-12 p-2 border customer_records" style={{ backgroundColor: '#F6F6F6' }}>
                    <div className="mb-3">
                        <input type="text" className="form-control  rounded-0 textOnly mt-2" id="validationTooltip01" name="clientname"  onChange={handleChange} placeholder="Client name*" />
                        <div className="invalid-feedback small">{errors.clientname}</div>
                        <input type="text" className="form-control  rounded-0 textOnly mt-2" id="validationTooltip01" name="firstname" maxLength={30}  onChange={handleChange} placeholder="Reporting Manager First Name *" />
                        <div className="invalid-feedback small">{errors.firstname}</div>
                        <input type="text" className="form-control  rounded-0 textOnly mt-2" id="validationTooltip01" name="lastname" maxLength={30}  onChange={handleChange} placeholder="Reporting Manager Last Name *" />
                        <div className="invalid-feedback small">{errors.lastname}</div>
                        <input type="text" className="form-control  rounded-0 textOnly mt-2" id="validationTooltip01" name="telephone"  maxlength="10"  onChange={handleChange} placeholder="Reporting Manager Telephone Number *" />
                        <div className="invalid-feedback small">{errors.telephone}</div>
                        <input type="text" className="form-control  rounded-0 textOnly mt-2" id="validationTooltip01" name="mobileno" maxlength="10" onChange={handleChange} placeholder="Reporting Manager Mobile Number *" />
                        <div className="invalid-feedback small">{errors.mobileno}</div>
                        <input type="text" className="form-control  rounded-0 textOnly mt-2" id="validationTooltip01" name="email" onChange={handleChange} placeholder="Reporting Manager Email *" />
                        <div className="invalid-feedback small">{errors.email}</div>
                        <div className="alert-success small">{message}</div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="mb-2 col-md-12 mt-5 text-center">
                    <button type="reset" className="btn btn-secondary ml-2" data-dismiss="modal">Cancel</button>
                    <button type="submit" className="btn btn-primary ml-2" onClick={validateForm}>Submit</button>
                </div>
            </Modal.Footer>
            </form>
        </Modal>
    </>
  )
}

export default Myreportingmanager
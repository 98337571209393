import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import AuthUser from './AuthUser';
import Doc from '../src/images/doc.png'
import Pdf from '../src/images/pdf.png'
import Headerlogo from '../src//images/H1B_Applicants_logo.png';
import Viewdocument from '../src/Common/Viewdocument';
import Spinner from 'react-bootstrap/Spinner';
import Navbar from 'react-bootstrap/Navbar';
function Searchpfa() {
    const[loader,setLoader]=useState(false);
    const [sampledocs, setSampledocs] = useState([]);
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
    const [modalshow, setModalshow] = useState(false);
    const [allChecked, setAllChecked] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [selectedid, setSelectedid] = useState(null);
    const { http, getUser } = AuthUser();
    const [file, setFile] = useState(false);
    const { id } = useParams();
    const handleModalShow = (filename) =>{
        setModalshow(true);
        setFile(filename);
    } 
    const fetchItems = async () => {
        http.get(`/searchpfadata/${id}`)
        .then((response) => {
            console.log(response.data);
            setSampledocs(response.data.sampledocs);
        })
        .catch(function (error) {
            // handle error
            console.log(error.response.data.error);
        });
      };
    useEffect(() => {
        fetchItems();
    }, []);
    const handleCheckboxChange = (value) => {
        // Update the selected checkboxes array when a checkbox is checked or unchecked
        setSelectedCheckboxes((prevSelected) =>
          prevSelected.includes(value)
            ? prevSelected.filter((item) => item !== value)
            : [...prevSelected, value]
        );
    };
    const handleAllCheckboxChange = () => {
        setAllChecked(!allChecked);
        setSelectedCheckboxes(sampledocs.map(li => li.documents));
        if (allChecked) {
            setSelectedCheckboxes([]);
        }
    };
    //Download
    const handleDownload = () =>{
        setLoader(true)
        http.post(`employer/downloadFiles`,{files:selectedCheckboxes}, {
            responseType: 'blob',
        })
        .then((response) => {
            //console.log(response);
            let filename = (selectedCheckboxes.length === 1) ? selectedCheckboxes : 'download.zip';
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url; //''
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setLoader(false)
        })
        .catch(function (error) {
            // handle error
            console.log(error.response.data.error);
        });
    }
  return (
    <div className="container-fluid">
        <Navbar expand="md" className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
            <Navbar.Brand href="/"><img src={Headerlogo} width="80%" alt="H1B Applicants" /></Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll" />
        </Navbar>
        <div className="row mb-2 ">
            <div className="col-md-4">
                
            </div>
            <div className="col-md-4 small  text-right">
                <div className="row">
                    
                </div>

            </div>
        </div>
        <div className="row">
            <div className="col-md-6">&nbsp;</div>
            <div className="col-md-6 text-right">
                <input className="form-check-input" type="hidden" name="allmerge[]" />
                <input type="hidden" name="user_id" value="1" />
                <input type="hidden" className="form-control title" list="datalistOptions" id="exampleDataList" name="document_type" value="Sample" />
                { /* <button type="submit" className="btn btn-primary ml-2 mb-2" id="frm-download" name="merge" onClick={handleDownload}
                    value="download">Download </button> */ }
            </div>
        </div>
        <div className="row">
            <div className="col-md-12 pb-5">
                <div className="card  mb-4">
                    <div className="card-body">
                        {loader === true &&
                            <Spinner animation="border" className='mx-auto' role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        }        
                        <div className="table-responsive">
                            <table data-toggle="table" data-classes="table table-hover table-condensed"
                                data-striped="true" data-sort-name="Quality" data-sort-order="desc" id="Tabla"
                                className="Tabla table table-hover table-condensed" data-page-length='10'>
                                <thead>
                                    <tr>
                                        <th data-sortable="true">Case Number</th>
                                        <th data-sortable="true"><i className="bi-paperclip"></i></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {sampledocs.map(sampleDoc => (
                                        
                                        <tr key={sampleDoc.id}>
                                            <td>
                                            <div className="row">
                                                <div className="col-9">{sampleDoc.title}</div>
                                            </div>
                                            </td>
                                            <td><a href="javascript:void(0);" onClick={() => handleModalShow(sampleDoc.documents)} ><img src={sampleDoc.documents.split('.').pop() == 'pdf' ? Pdf : Doc} />{sampleDoc.title}</a></td>
                                            
                                        </tr>
                                    ))}
                                    {sampledocs.length === 0 &&
                                        <tr>
                                            <td colSpan={2} className='text-center'> No case number found</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Viewdocument show={modalshow} filename={file} foldertype="admindocs" close={() => setModalshow(false)} />    
    </div>
  )
}

export default Searchpfa
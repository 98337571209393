import React,{useState,useEffect} from 'react'
import Headerlogo from '../images/H1B_Applicants_logo.png';
import Footerlogo from '../images/tns-logo.jpg';
import '../css/H1B_Applicants.css';
import axios from 'axios';
import AuthUser from '../AuthUser';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

function Applicantregister(props) {
    const {http,setToken} = AuthUser();    
    const [values,setValues]=useState({name:'',lastname:'',email:'',phone:''});
    const [errors, setErrors] = useState({name:'',lastname:'',email:'',phone:'',message:'' });    
    const [message, setMessage] = useState();
    const [submitted, setsubmitted] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState();
    const [country, setCountry] = useState();
    const [countrycode, setCountrycode] = useState();
    const[show,setShow]=useState(false);
    let pageview=props.pageview;
    let pageurl=props.apiurl;
    let logint = props.logintype;
    const userSetting = sessionStorage.getItem('usersettings');
    const user_setting = JSON.parse(userSetting);
    const [usersettings,setUsersettings]=useState(user_setting);
    let showpage = logint==='applicant' ? (usersettings.applicant_register === 'Y' ? true:false)  : logint==='employer' ? (usersettings.employer_register === 'Y' ? true : false) : logint==='attorney' ? (usersettings.attorney_register === 'Y' ? true : false) : false;
    const handlePhoneChange = (phone, data) => {
    // Handle phone number change
    console.log(data);
    //console.log(data.dialCode);
    //console.log(value.target.phone.value)
    //console.log(value.slice(data.dialCode.length));
    console.log(phone)
    setCountrycode(data.countryCode);
    setCountry(data.dialCode);
    //let val = phone.slice(data.dialCode.length);
    setPhoneNumber(phone);
    const phoneNumberPattern = /^\+?[1-9]\d{1,14}$/;

    phoneNumberPattern.test(phone);
    setValues({...values,['phone']:phone})
  };
    function handleChange(e){
        setValues({...values,[e.target.name]:e.target.value})
    }
    const validateForm = () => {
        let isValid = true;
        const newErrors = { ...errors };
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const alphaRegex = /^[A-Za-z]+$/;
        // Validate email
        if (!values.email.trim()) {
            newErrors.email = 'Email is required';
            isValid = false;
        } else  if(!emailRegex.test(values.email)){
            newErrors.email = 'Email not valid';
            isValid = false;
        }else {
            newErrors.email = '';
        }

        // Validate Name
        if (!values.name.trim()) {
            newErrors.name = 'First Name required';
            isValid = false;
        } else  if(!alphaRegex.test(values.name)){
            newErrors.name = 'Only Characters allowed';
            isValid = false;
        }else {
            newErrors.name = '';
        }
        if (!values.lastname.trim()) {
            newErrors.lastname = 'Last Name required';
            isValid = false;
        } else  if(!alphaRegex.test(values.lastname)){
            newErrors.lastname = 'Only Characters allowed';
            isValid = false;
        }else {
            newErrors.lastname = '';
        }
        if (!values.phone) {
            newErrors.phone = 'Phone Number required';
            isValid = false;
        } else {
            newErrors.phone = '';
        }
        setErrors(newErrors);
        return isValid;
    };
    const handleSubmit = async (e) =>{
        e.preventDefault();
        //validation(values);
        let name = values.name;
        let lastname = values.lastname;
        let email = values.email;
        let phone = values.phone;
        let code = country;
        let shortcode = countrycode;
        const newErrors = { ...errors };
        
        if (validateForm()) {
          http.post(`${pageurl}`,{name:name,lastname:lastname,email:email,phone:phone,countrycode:code,shortcode:shortcode}).then((response)=>{
              //setToken(response.data.userdetail,response.data.token);
              //console.log(response.status);
              console.log(response.data);
              setValues({name:'',lastname:'',email:'',phone:''});
              setPhoneNumber('');
              setMessage(response.data.message);
              
          }).catch(function (error) {
            // handle error
            console.log(error);
            //console.log(error.response.data.error);
            
            if(error?.response?.status==403){
                newErrors.name = error.response.data?.error?.name;
                newErrors.lastname = error.response.data?.error?.lastname;
                newErrors.email = error.response.data?.error?.email;
                newErrors.phone = error.response.data?.error?.phone;
              setErrors(newErrors);
            }
            if(error.response.status==401){
              newErrors.message = error.response.data?.error;
              setErrors(newErrors);
            }
            
            //setsubmitted(false)
          })
        } else {
          // Form is not valid, display error messages
    
        }
    }
  return (
    <>
        <div className="row mt-5">
            <div className="col-md-3 m-auto border bg-white">
                <section className="p-2 right-section">
                    <div className="col-lg-10 m-auto  ">
                        <a href="/"><img src={Headerlogo} alt="H1BAPPLICANTS.COM" width="100%" /></a>
                        <h5 className="mt-3">{pageview}</h5>
                            {showpage === true ? 
                            <form method="POST" onSubmit={handleSubmit}  >
                                <div className="col-md-12">
                                    <div className="input-container ic1">
                                        <input id="firstname" name="name" className="input form-control textOnly"
                                            type="text" placeholder="" onChange={handleChange} value={values.name} maxLength={30}/>
                                        <div className="cut"></div>
                                        <label htmlFor="firstname" className="placeholder">First name *</label>
                                        <div className="invalid-feedback small">{errors.name}</div>
                                    </div>
                                    <div className="input-container ic1">
                                        <input id="lastname" className="input form-control textOnly" type="text"
                                            name="lastname" onChange={handleChange} value={values.lastname} placeholder="" maxLength={30} />
                                        <div className="cut"></div>
                                        <label htmlFor="Last name " className="placeholder">Last name *</label>
                                        <div className="invalid-feedback small">{errors.lastname}</div>
                                    </div>
                                    <div className="input-container ic1">
                                        <input type="Email" className="form-control mt-2 mb-2 input" id="Email"
                                            name="email" onChange={handleChange} value={values.email} placeholder=" " />
                                        <div className="cut"></div>
                                        <label htmlFor="Email" className="placeholder">Email *</label>
                                        <div className="invalid-feedback small">{errors.email}</div>
                                    </div>
                                    <div className="input-container ic1">
                                    {logint !=='applicant' ? 
                                        <PhoneInput  style={{ width:'100% !important' }} country={'us'} onlyCountries={['us']} name="phone" value={phoneNumber} onChange={handlePhoneChange}/>
                                         : 
                                        <PhoneInput  style={{ width:'100% !important' }} country={'us'}  name="phone" value={phoneNumber} onChange={handlePhoneChange}/>
                                    }
                                        
                                        <div className="invalid-feedback small">{errors.phone}</div>
                                    </div>
                                    <div className="alert-success small">{message}</div>                                
                                </div>
                                <div className="col-md-12 text-center">
                                    <button type="submit" className="btn text-white mt-2 rounded-0"
                                        style={{ backgroundColor: '#2488B3' }} onClick={validateForm} disabled={submitted}><strong>Submit</strong></button>
                                </div>
                            </form>
                            : 
                            <h3>No access to register</h3>
                            }
                    </div>
                </section>
            </div>
        </div>
        <div className="col-md-3 mt-3 m-auto text-center">
            <p><strong>Owned &amp; managed by </strong></p>
        </div>
        <div className="col-md-3 m-auto text-center bg-white p-2 border"> <a href="https://www.tnsservices.com/"
                target="_blank"><img src={Footerlogo} width="70%"
                    alt="Trans National Software Services Inc." /></a> </div>
    </>
  )
}

export default Applicantregister
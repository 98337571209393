import React,{useState} from 'react'
import AuthUser from '../AuthUser';
function Addlca() {
    const [divs, setDivs] = useState([]);
    const [count,setCount] = useState(1);
    const[message,setMessage]=useState();
  const addDiv = () => {
    setDivs([...divs, <div key={divs.length+1} className="col-md-12 bg-light pt-2 border">
    <label className="form-label " style={{ color: '#2D8BB4' }}><strong>OPERATING AT THE WORK LOCATION</strong></label>
    <div className="row">
        <div className="col-md-2">
            <div className="form-check">
                <input className="form-check-input actionOPT" type="radio"  name={`operating_at[${divs.length+1}]`} key={divs.length+1} onChange={(e) => handleOpChange(divs.length+1, e.target.value)} value="Client"  />
                <label className="form-check-label" htmlFor="flexRadioDefault1"> Client </label>
            </div>
        </div>
        <div className="col-md-2">
            <div className="form-check">
                <input className="form-check-input actionOPT" type="radio" name={`operating_at[${divs.length+1}]`} key={divs.length+1} onChange={(e) => handleOpChange(divs.length+1, e.target.value)} value="Company"  />
                <label className="form-check-label" htmlFor="flexRadioDefault2"> Company </label>
            </div>
        </div>
        <div className="col-md-3">
            <div className="form-check">
                <input className="form-check-input actionOPT" type="radio" name={`operating_at[${divs.length+1}]`} key={divs.length+1} onChange={(e) => handleOpChange(divs.length+1, e.target.value)} value="Wfh" />
                <label className="form-check-label" htmlFor="flexRadioDefault3"> Work from Home </label>
            </div>
        </div>
    </div>
    <label className="form-label"><strong>CLIENT NAME</strong></label>
    <input className="form-control rounded-0" type="text" placeholder="" name={`client_name[${divs.length+1}]`} key={divs.length+1} onChange={(e) => handleClientChange(divs.length+1, e.target.value)} id="clientname0"  />
    <label className="form-label mt-2"><strong>WORK LOCATION 1</strong></label>
    <textarea className="form-control rounded-0" rows="3" name={`work_location[${divs.length+1}]`} key={divs.length+1} onChange={(e) => handleWorkChange(divs.length+1, e.target.value)}  ></textarea>
    <span className=" text-black-50">ADD MULTIPLE WORK LOCATIONS, IF REQUIRED</span>
</div>]);
  };

  const removeDiv = (id) => {
    console.log(id);
    const newTasks = [...divs];
        newTasks.splice(id, 1);
        setDivs(newTasks);
    //const updatedDivs = divs.filter((div) => div !== id);
    //setDivs(updatedDivs);
    //const updatedDivs = divs.filter((div) => div.id !== id);
    //const updatedDivs = [...divs];
    //updatedDivs.pop();
    //setDivs(updatedDivs);
    //const updatedDivs = [...divs];
    //    updatedDivs.splice(id, 1);
    //    setDivs(updatedDivs);
  };
    const [operating,setOperating]=useState([]);
    const [client,setClient]=useState([]);
    const [work,setWork]=useState([]);
    const {http,getUser} = AuthUser();
    const [values,setValues]=useState({firstname:'',middlename:'',lastname:'',jobtitle:'',worklocation:'',clientname:'',worklocation:'',wagelevel:'',jobduties:'',empcount:''});
    const [errors, setErrors] = useState({firstname:'',middlename:'',lastname:'',jobtitle:'',worklocation:'',clientname:'',worklocation:'',wagelevel:'',jobduties:'',empcount:''});
    let userdetails = getUser();
    function handleChange(e){
        setValues({...values,[e.target.name]:e.target.value})
    }
    
    const handleOpChange = (index, value) => {
        const updatedValues = [...operating];
        updatedValues[index] = value;
        setOperating(updatedValues);
    };
    
    const handleClientChange = (index, value) => {
        const updatedClient = [...client];
        updatedClient[index] = value;
        setClient(updatedClient);
    };
    
    const handleWorkChange = (index, value) => {
        const updatedWork = [...work];
        updatedWork[index] = value;
        setWork(updatedWork);
    };
    const validateForm = () => {
        let isValid = true;
        const newErrors = { ...errors };
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const alphaRegex = /^[A-Za-z]+$/;
        let emailerror;
        // Validate email
        if (!values.firstname.trim()) {
            newErrors.firstname = 'First Name required';
            isValid = false;
        } else if(!alphaRegex.test(values.firstname)){
            newErrors.firstname = 'Only Characters allowed';
            isValid = false;
        }else {
            newErrors.firstname = '';
        }
        if (!values.lastname.trim()) {
            newErrors.lastname = 'Last Name required';
            isValid = false;
        } else if(!alphaRegex.test(values.lastname)){
            newErrors.lastname = 'Only Characters allowed';
            isValid = false;
        }else {
            newErrors.lastname = '';
        }
        if (!values.middlename.trim()) {
            newErrors.middlename = 'Middle Name required';
            isValid = false;
        } else if(!alphaRegex.test(values.middlename)){
            newErrors.middlename = 'Only Characters allowed';
            isValid = false;
        }else {
            newErrors.middlename = '';
        }
        if (!values.jobtitle.trim()) {
            newErrors.jobtitle = 'Job title required';
            isValid = false;
        } else {
            newErrors.jobtitle = '';
        }
        if (!values.wagelevel.trim()) {
            newErrors.wagelevel = 'Wage level required';
            isValid = false;
        } else {
            newErrors.wagelevel = '';
        }
        if (!values.jobduties.trim()) {
            newErrors.jobduties = 'Job duties required';
            isValid = false;
        } else {
            newErrors.jobduties = '';
        }
        setErrors(newErrors);
        return isValid;
    };
    const handleSubmit = async (e) =>{
        e.preventDefault();
        let firstname = values.firstname;
        let middlename = values.middlename;
        let lastname = values.lastname;
        let jobtitle = values.jobtitle;
        let operatingat = operating;
        let clientname = client;
        let worklocation = work;
        let wagelevel = values.wagelevel;
        let jobduties = values.jobduties;
        let empcount = count;
        if (validateForm()) {
          http.post(`employer/addlca`,{firstname:firstname,middlename:middlename,lastname:lastname,jobtitle:jobtitle,operating_at:operatingat,client_name:clientname,work_location:worklocation,wagelevel:wagelevel,jobduties:jobduties,empcount:empcount,userid:userdetails.id}).then((response)=>{
              
              //console.log(response.data);
              e.target.reset();
              setMessage('Lca submited successfully!');
              
          }).catch(function (error) {
            // handle error
            //console.log(error.response.data.error);
            if(error.response.status==403){
              //emailerror = error.response.data?.error?.email;
              //setErrors(emailerror);
            }
            if(error.response.status==401){
                //emailerror = error.response.data?.error;
                //setErrors(emailerror);
            }
            //setsubmitted(false)
          })
        } else {
          // Form is not valid, display error messages
    
        }
    }
  return (
    <div className="container-fluid">
        <div className="col-md-6 m-auto p-0 pb-2 ">
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb p-0">
                    <li className="breadcrumb-item "><a href="/employer">Dashboard</a></li>
                    <li className="breadcrumb-item active" aria-current="page"><h6 className="mb-2 font-weight-bold">Create LCA</h6></li>
                </ol>
            </nav>
        </div>
        <div className="col-md-6 bg-white m-auto shadow-sm p-4 " style={{ borderTop: '5px solid #DF8E2E' }}>
            <form method="POST" onSubmit={handleSubmit}  >
                
                <div className="row mb-4">
                    <div className="mb-2 col-md-6">
                        <label className="form-label " style={{ color: '#2D8BB4' }}><strong>FIRST NAME</strong></label>
                        <input className="form-control rounded-0" type="text" name="firstname" id="firstname" onChange={handleChange}  placeholder="" />
                        <div className="invalid-feedback small">{errors.firstname}</div>
                    </div>
                    <div className="mb-2 col-md-6">
                        <label className="form-label " style={{ color: '#2D8BB4' }}><strong>MIDDLE NAME</strong></label>
                        <input className="form-control rounded-0" type="text" name="middlename"  id="middlename" onChange={handleChange}  placeholder="" />
                        <div className="invalid-feedback small">{errors.middlename}</div>
                    </div>
                    <div className="mb-2 col-md-6">
                        <label className="form-label " style={{ color: '#2D8BB4' }}><strong>LAST NAME</strong></label>
                        <input className="form-control rounded-0" type="text" name="lastname" id="lastname" onChange={handleChange}  placeholder="" />
                        <div className="invalid-feedback small">{errors.lastname}</div>
                    </div>
                    <div className="mb-2 col-md-6">
                        <label className="form-label " style={{ color: '#2D8BB4' }}><strong>JOB TITLE</strong></label>
                        <input className="form-control  rounded-0" type="text" name="jobtitle" id="jobtitle" onChange={handleChange}  placeholder="" />
                        <div className="invalid-feedback small">{errors.jobtitle}</div>
                    </div>
                    <div className="mb-2 col-md-12 mt-2 input_fields_wrap ">
                        <div className="col-md-12 bg-light pt-2 border">
                            <label className="form-label " style={{ color: '#2D8BB4' }}><strong>OPERATING AT THE WORK LOCATION</strong></label>
                            <div className="row">
                                <div className="col-md-2">
                                    <div className="form-check">
                                        <input className="form-check-input actionOPT" type="radio" key='0' name="operating_at[0]" onChange={(e) => handleOpChange(0, e.target.value)} value="Client" />
                                        <label className="form-check-label" htmlFor="flexRadioDefault1"> Client </label>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-check">
                                        <input className="form-check-input actionOPT" type="radio" key='0' name="operating_at[0]" onChange={(e) => handleOpChange(0, e.target.value)}  value="Company" />
                                        <label className="form-check-label" htmlFor="flexRadioDefault2"> Company </label>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-check">
                                        <input className="form-check-input actionOPT" type="radio" key='0' name="operating_at[0]" onChange={(e) => handleOpChange(0, e.target.value)}  value="Wfh"  />
                                        <label className="form-check-label" htmlFor="flexRadioDefault3"> Work from Home </label>
                                    </div>
                                </div>
                            </div>
                            <label className="form-label"><strong>CLIENT NAME</strong></label>
                            <input className="form-control rounded-0" type="text" placeholder="" name="client_name[0]" key='0' onChange={(e) => handleClientChange(0, e.target.value)} />
                            <label className="form-label mt-2"><strong>WORK LOCATION 1</strong></label>
                            <textarea className="form-control rounded-0" rows="3" name="work_location[0]" key='0' onChange={(e) => handleWorkChange(0, e.target.value)} ></textarea>
                            <span className=" text-black-50">ADD MULTIPLE WORK LOCATIONS, IF REQUIRED</span>
                        </div>                    
                    </div>
                    {divs.map((div,index) => (
                        <div key={index} className="mb-2 col-md-12 mt-2 input_fields_wrap ">
                            {div}
                            <a href="#" onClick={() => removeDiv(index)} className="remove_field">Remove</a>
                        </div>
                    ))}
                    <div className="col-md-12 text-right border-bottom pb-2"> <a href="#" onClick={addDiv}
                            className="add_field_button btn-primary btn rounded-0"><i className="bi bi-plus-lg"></i> Add more</a>
                    </div>
                    <div className="mb-2 col-md-12 mt-2 ">
                        <div className="col-md-12">
                            <label className="form-label " style={{ color: '#2D8BB4' }}><strong>WAGE LEVEL</strong></label>
                            <div className="row">
                                <div className="invalid-feedback small">{errors.wagelevel}</div>
                                <div className="col-md-2">
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="wagelevel" id="wagelevel" onChange={handleChange}  value="LEVEL 1" />
                                        <label className="form-check-label" htmlFor="flexRadioDefault1"> LEVEL 1 </label>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="wagelevel" id="wagelevel" onChange={handleChange}  value="LEVEL 2" />
                                        <label className="form-check-label" htmlFor="flexRadioDefault1"> LEVEL 2 </label>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="wagelevel" id="wagelevel" onChange={handleChange} value="LEVEL 3" />
                                        <label className="form-check-label" htmlFor="flexRadioDefault2"> LEVEL 3 </label>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="wagelevel" id="wagelevel" onChange={handleChange}  value="LEVEL 4" />
                                        <label className="form-check-label" htmlFor="flexRadioDefault3"> LEVEL 4 </label>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="wagelevel" id="wagelevel" onChange={handleChange} value="NOT SURE" />
                                        <label className="form-check-label" htmlFor="flexRadioDefault3"> NOT SURE </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mb-2 col-md-12 mt-2">
                        <label className="form-label " style={{ color: '#2D8BB4' }}><strong>JOB DUTIES</strong></label>
                        <div id="loader" className="center"></div>
                        <textarea id="editor" className="form-control rounded-0" rows="3" name="jobduties" onChange={handleChange}></textarea>
                        <div className="invalid-feedback small">{errors.jobduties}</div>
                    </div>
                    <div className="mb-2 col-md-12 mt-2">
                        <label className="form-label" style={{ color: '#2D8BB4' }}><strong>EMPLOYEE COUNT AS OF TODAY</strong></label>
                        <div className="qty row col-md-6">  
                            <div className='col-md-2'><a className="add_field_button btn-primary btn rounded-0" onClick={() => setCount(prevCount => (prevCount == 0) ? 0 : parseInt(prevCount)-1)}> <span className="minus bg-primary">-</span></a></div>
                            <div className='col-md-8'><input type="number" className="form-control" style={{ border:'1px'}}id="empcount" name="empcount" onChange={handleChange}  value={count} /></div>
                            <div className='col-md-2'><a className="add_field_button btn-primary btn rounded-0" onClick={() => setCount( prevCount => parseInt(prevCount)+1) }> <span className="plus bg-primary" >+</span></a></div>                          
                            <br></br>
                            <br></br>
                            <br></br>
                        </div>
                    </div>
                    <div className="alert-success small">{message}</div>
                    <div className="col-12 mt-3 text-center">
                        <button className="btn text-white btn-secondary rounded-0" type="button">Cancel</button>&nbsp;&nbsp;
                        <button className="btn text-white rounded-0" type="submit" style={{ backgroundColor: '#81A744' }} >Submit</button>
                    </div>
                </div>
                <div className="modal fade" id="preview" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel"><strong>Preview LCA Details</strong></h5>
                                <button className="close" type="button" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true">×</span> </button>
                            </div>
                        <div className="modal-body">
                            <div className="row mb-4">
                                <div className="mb-2 col-md-6">
                                    <label  className="form-label " style={{ color: '#2D8BB4' }}><strong>FIRST NAME</strong></label>
                                    <p id="pfirstname"></p>
                                </div>
                                <div className="mb-2 col-md-6">
                                    <label  className="form-label " style={{ color: '#2D8BB4' }}><strong>MIDDLE NAME</strong></label>
                                    <p id="pmiddlename"></p>
                                </div>
                                <div className="mb-2 col-md-6">
                                    <label  className="form-label " style={{ color: '#2D8BB4' }}><strong>LAST NAME</strong></label>
                                    <p id="plastname"></p>
                                </div>
                                <div className="mb-2 col-md-6">
                                    <label  className="form-label " style={{ color: '#2D8BB4' }}><strong>JOB TITLE</strong></label>
                                    <p id="pjobtitle"></p>
                                </div>
                                <div className="orders_preview"></div>
                                <div className="col-md-12">
                                <label  className="form-label " style={{ color: '#2D8BB4' }}><strong>WAGE LEVEL</strong></label>
                                    <p id="pwagelevel"></p>  
                                </div>
                                <div className="mb-2 col-md-12 mt-4">
                                    <label  className="form-label " style={{ color: '#2D8BB4' }}><strong>JOB DUTIES</strong></label>
                                    <p id="pjobduties"></p>
                                </div>
                                <div className="mb-2 col-md-12">
                                    <label  className="form-label" style={{ color: '#2D8BB4' }}><strong>EMPLOYEE COUNT AS OF TODAY</strong></label>
                                    <p id="pempcount"></p>
                                </div>
                                <div className="col-12 mt-3 text-center">
                                    <button className="btn text-white btn-secondary rounded-0" type="button"  data-dismiss="modal" aria-label="Close">Cancel</button>&nbsp;&nbsp; 
                                    <button className="btn text-white rounded-0" type="submit" style={{ backgroundColor: '#81A744' }}>Submit</button>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
  )
}

export default Addlca
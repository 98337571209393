import React from 'react';
import { Routes, Route, Link, Router, Navigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import AuthUser from '../AuthUser';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Headerlogo from '../images/H1B_Applicants_logo.png';
import EmployerDashboard from '../Employer/EmployerDashboard';
import Changepassword from '../Common/Changepassword';
import Myprofile from '../Common/Myprofile';
import SampleDocuments from '../Common/Sampledocuments';
import Sendinvite from '../Employer/Sendinvite';
import Ticketslist from '../Common/Ticketslist';
import Applicantslist from '../Common/Applicantslist';
import Withdrawdocuments from '../Employer/Withdrawdocuments';
import Lcalist from '../Employer/Lcalist';
import Addlca from '../Employer/Addlca';
import Editprofile from '../Common/Editprofile';
import Mydocuments from '../Applicants/Mydocuments';
import Viewinvites from '../Employer/Viewinvites';
import Resetpassword from '../Common/Resetpassword';
import Newemployeedocumentation from '../Applicants/Newemployeedocumentation';
import Paychexlogin from '../Applicants/Paychexlogin';
import Payrollcalendar from '../Applicants/Payrollcalendar';
import Eventslist from '../Employer/Eventslist';
import Suggestionlist from '../Employer/Suggestionlist';
import Feedbacklist from '../Employer/Feedbacklist';
import Timesheetdetails from '../Employer/Timesheetdetails';
import Performancereviewlist from '../Employer/Performancereviewlist';
import Timesheetdetailreport from '../Employer/Timesheetdetailreport';
import Requestslist from '../Employer/Requestslist';
import Referalpolicylist from '../Employer/Referalpolicylist';
import Employees from '../Employer/Employees';
function EmployerNav() {
    const {token,logout,getUser} = AuthUser();
    let userdetails = getUser();
    let loguser = userdetails.name+" "+userdetails.lastname;
    const logoutUser = () => {
        if(token !== undefined){
            logout();
        }
    }
    const isAuthenticated = (userdetails !== '') ? true : false;
  return (
    <>
        <Navbar expand="md" className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
            <Navbar.Brand href="/employer"><img src={Headerlogo} width="80%" alt="H1B Applicants" /></Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
            <NavDropdown className="navbar-nav ml-auto" title={loguser} id="navbarScrollingDropdown">
                <NavDropdown.Item href="/employer/myprofile"><i className="bi-person-fill"></i>My Profile</NavDropdown.Item>
                <NavDropdown.Item href="/employer/editprofile"><i className="bi-person-fill"></i>Edit Profile</NavDropdown.Item>
                <NavDropdown.Item href="/employer/changepassword"><i className="bi-key-fill"></i>Change Password</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={logoutUser}>Logout</NavDropdown.Item>
            </NavDropdown>
            </Navbar.Collapse>
        </Navbar>
        <Routes>
            <Route path="/employer" element={isAuthenticated ? <EmployerDashboard logintype="employer" /> : <Navigate to="/" replace /> } />
            <Route path="/employer/tickets" element={isAuthenticated ? <Ticketslist logintype="employer" pageview="Tickets" apiurl="ticketslist"/> : <Navigate to="/" replace /> } />
            <Route path="/employer/premiumtickets" element={isAuthenticated ? <Ticketslist logintype="employer" pageview="Premium Tickets" apiurl="premiumtickets"/> : <Navigate to="/" replace /> } />
            <Route path="/employer/regulartickets" element={isAuthenticated ? <Ticketslist logintype="employer" pageview="Regular Tickets" apiurl="regulartickets"/> : <Navigate to="/" replace /> } />
            <Route path="/employer/changepassword" element={isAuthenticated ? <Changepassword logintype="employer" /> : <Navigate to="/" replace /> } />
            <Route path="/employer/myprofile" element={isAuthenticated ? <Myprofile logintype="employer"/> : <Navigate to="/" replace /> } />
            <Route path="/employer/viewinvites" element={isAuthenticated ? <Viewinvites logintype="employer"/> : <Navigate to="/" replace /> } />
            <Route path="/employer/editprofile" element={isAuthenticated ? <Editprofile logintype="employer"/> : <Navigate to="/" replace /> } />
            <Route path="/employer/applicants" element={isAuthenticated ? <Applicantslist logintype="employer" pageview="IT Consultants" apiurl="applicants"/> : <Navigate to="/" replace /> } />
            <Route path="/employer/gc" element={isAuthenticated ? <Applicantslist logintype="employer" pageview="GC Consultants" apiurl="applicants"/> : <Navigate to="/" replace /> } />
            <Route path="/employer/formnine" element={isAuthenticated ? <Applicantslist logintype="employer" pageview="Form I9 info" apiurl="applicants"/> : <Navigate to="/" replace /> } />
            <Route path="/employer/publicDocuments" element={isAuthenticated ? <SampleDocuments logintype="employer" pageview="Public Documents" doctype="PUBLIC" apiurl="publicdocsapi" /> : <Navigate to="/" replace /> } />
            <Route path="/employer/h1bQuestionaire" element={isAuthenticated ? <SampleDocuments logintype="employer" pageview="H1b Questionaire" doctype="H1BQ" apiurl="h1bdocsapi" /> : <Navigate to="/" replace /> }/>
            <Route path="/employer/gcaudits" element={isAuthenticated ? <SampleDocuments logintype="employer" pageview="GC Audit logs" doctype="GCAUDIT" apiurl="gcdocsapi" /> : <Navigate to="/" replace /> } />
            <Route path="/employer/sampledocuments" element={isAuthenticated ? <SampleDocuments logintype="employer" pageview="Sample Documents" doctype="Sample" apiurl="sampledocsapi" /> : <Navigate to="/" replace /> } />
            <Route path="/employer/publicCompliance" element={isAuthenticated ? <SampleDocuments logintype="employer" pageview="Public Access Files Compliance" doctype="PUBLICCOMP" apiurl="publiccompdocsapi"/> : <Navigate to="/" replace /> } />
            <Route path="/employer/uscisDocuments" element={isAuthenticated ? <SampleDocuments logintype="employer" pageview="USCIS Documents" doctype="USCIS" apiurl="uscisdocsapi"/> : <Navigate to="/" replace /> } />
            <Route path="/employer/msaDocuments" element={isAuthenticated ? <SampleDocuments logintype="employer" pageview="MSA Documents" doctype="MSA" apiurl="msadocsapi"/> : <Navigate to="/" replace /> } />
            <Route path="/employer/sowDocuments" element={isAuthenticated ? <SampleDocuments logintype="employer" pageview="SOW Documents" doctype="SOW" apiurl="sowdocsapi"/> : <Navigate to="/" replace /> } />
            <Route path="/employer/prelcafiling" element={isAuthenticated ? <SampleDocuments logintype="employer" pageview="Pre LCA filing posting Notice" doctype="PRELCA" apiurl="prelcaapi" /> : <Navigate to="/" replace /> } />
            <Route path="/employer/inprocesslca" element={isAuthenticated ? <SampleDocuments logintype="employer" pageview="Inprocess LCA" doctype="INPROCLCA" apiurl="inprocesslcaapi" /> : <Navigate to="/" replace /> } />
            <Route path="/employer/sendInvite" element={isAuthenticated ? <Sendinvite /> : <Navigate to="/" replace /> } />
            <Route path="/employer/withdrawDocuments" element={isAuthenticated ? <Withdrawdocuments logintype="employer" pageview="Withdraw Documents" doctype="WITHDRAW" apiurl="msadocsapi" /> : <Navigate to="/" replace /> } />
            <Route path="/employer/lca" element={isAuthenticated ? <Lcalist logintype="employer" apiurl="lcalist"/> : <Navigate to="/" replace /> } />
            <Route path="/employer/addlca" element={isAuthenticated ? <Addlca /> : <Navigate to="/" replace /> } />    
            <Route path="/employer/mydocs/:id/:doctype" element={isAuthenticated ? <Mydocuments logintype="employer" /> : <Navigate to="/" replace /> } />
            <Route path="/employer/resetpassword/:token/:email" element={isAuthenticated ? <Resetpassword logintype="employer" pageview="Reset Password" apiurl="passwordUpdate" /> : <Navigate to="/" replace /> } />
            <Route path="/employer/createpassword/:email" element={isAuthenticated ? <Resetpassword logintype="employer" pageview="Create Password" apiurl="updatePassword" /> : <Navigate to="/" replace /> } />
            <Route path="/employer/payrollcalendar" element={isAuthenticated ? <Payrollcalendar logintype="employer"/> : <Navigate to="/" replace /> } />
            <Route path="/employer/newempdocuments" element={isAuthenticated ? <Newemployeedocumentation logintype="employer" apiurl="empnewdocuments" /> : <Navigate to="/" replace /> }/>
            <Route path="/employer/paychexlogin" element={isAuthenticated ? <Paychexlogin logintype="employer"/> : <Navigate to="/" replace /> } />
            <Route path="/employer/events" element={isAuthenticated ? <Eventslist logintype="employer" /> : <Navigate to="/" replace /> } />
            <Route path="/employer/timesheet" element={isAuthenticated ? <Timesheetdetails logintype="employer" /> : <Navigate to="/" replace /> } />
            <Route path="/employer/timesheetreport/:month" element={isAuthenticated ? <Timesheetdetailreport logintype="employer" apiurl="timesheetreport" /> : <Navigate to="/" replace /> } />
            <Route path="/employer/performancereview" element={isAuthenticated ? <Performancereviewlist logintype="employer" apiurl="performancereview" /> : <Navigate to="/" replace /> } />
            <Route path="/employer/suggestions" element={isAuthenticated ? <Suggestionlist logintype="employer" apiurl="suggestion"/> : <Navigate to="/" replace /> }/>
            <Route path="/employer/feedbacks" element={isAuthenticated ? <Feedbacklist logintype="employer" apiurl="feedback"/> : <Navigate to="/" replace /> }/>
            <Route path="/employer/requests" element={isAuthenticated ? <Requestslist logintype="employer" apiurl="requestlist" /> : <Navigate to="/" replace /> } />
            <Route path="/employer/referralpolicy" element={isAuthenticated ? <Referalpolicylist logintype="employer" /> : <Navigate to="/" replace /> } />
            <Route path="/employer/employees" element={isAuthenticated ? <Employees /> : <Navigate to="/" replace /> } />
        </Routes>          
    </>
  )
}

export default EmployerNav
import React,{useState,useEffect} from 'react'
import AuthUser from '../AuthUser';
function Insurancevendorinfo() {
  const { http, getUser } = AuthUser();
    let userdetails = getUser();
    let usertype = 'applicant';
    let dashboard=`/${usertype}`;
    const [inxvendorinfo, setInxvendorinfo] = useState([]);
    useEffect(() => {
        http.get(`applicant/inxvendorinfo/${userdetails.email}`)
          .then((response) => {
            //console.log(response.data);
            setInxvendorinfo(response.data.inxvendorinfo);
          })
          .catch(function (error) {
            // handle error
            console.log(error.response.data.error);
          });
      }, []);
  return (
    
    <div className="container-fluid">
        <div className="col-md-6 m-auto p-0 pb-2 ">
            <div className='row'>
                <div className="col-md-6 text-left pull-left">
                    <h6 className="mb-2 font-weight-bold">Insurance Vendor Info</h6>
                </div>
                <div className="col-md-6 text-right pull-right">
                    <a href={dashboard} className="mb-2 font-weight-bold">Back</a>           
                </div>
            </div>
        </div>
        <div className="col-md-6 bg-white m-auto shadow-sm p-4 text-center" style={{ borderTop: '5px solid #DF8E2E' }}>
        
            <table data-toggle="table" data-classes="table table-hover table-condensed"
                data-striped="true" data-sort-name="Quality" data-sort-order="desc" id="Tabla"
                className="Tabla table table-hover table-condensed" data-page-length='10'>
                <thead>
                    <tr>
                        <th className="text-center">Vendor Name</th>
                        <th data-sortable="true">Contact Name</th>
                        <th data-sortable="true">Email</th>
                        <th data-sortable="true">Mobile</th>
                        <th data-sortable="true">Insurance Type</th>
                    </tr>
                </thead>
                <tbody>
                    {inxvendorinfo.map(inxvendor => (
                    <tr key={inxvendor.id}>
                        <td className="text-center">{ inxvendor.vendorname}</td>
                        <td>{ inxvendor.contactname}</td>
                        <td>{ inxvendor.email}</td>
                        <td>{ inxvendor.mobile}</td>
                        <td>{ inxvendor.insurancetype}</td>
                    </tr>
                    ))}
                </tbody>
            </table>
        </div>
    </div>
  )
}

export default Insurancevendorinfo
import React,{useState} from 'react';
import AuthUser from '../AuthUser';

function Sendinvite() {
    const [email,setEmail]=useState('');
    const [error, setError] = useState('');
    const[message,setMessage]=useState();
    const {http,getUser} = AuthUser();
    let userdetails = getUser();
    function handleChange(e){
        setEmail(e.target.value)
    }
    const validateForm = () => {
        let isValid = true;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        let emailerror;
        // Validate email
        if (!email.trim()) {
            emailerror = 'Email is required';
            isValid = false;
        } else  if(!emailRegex.test(email)){
            emailerror = 'Email not valid';
            isValid = false;
        }else {
            emailerror = '';
        }

        
        setError(emailerror);
        return isValid;
    };
    const handleSubmit = async (e) =>{
        e.preventDefault();
        //validation(values);
        let email = e.target.useremail.value;
        let emailerror;
        if (validateForm()) {
          http.post(`employer/sendinviteapi`,{useremail:email,userid:userdetails.id}).then((response)=>{
            setMessage('Invitation sent!');
              //console.log(response.data);
              
          }).catch(function (error) {
            // handle error
            console.error(error)
            //console.log(error.response.data.error);
            
            if(error.response.status==403){
              emailerror = error.response.data?.error?.useremail;
              setError(emailerror);
            }
            if(error.response.status==401){
                emailerror = error.response.data?.error;
                setError(emailerror);
            }
            
            //setsubmitted(false)
          })
        } else {
          // Form is not valid, display error messages
    
        }
    }
  return (
    <div className="container-fluid">
        <div className="row mb-2 ">
            <div className="col-md-4">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb p-0">
                        <li className="breadcrumb-item "><a href="/employer">Dashboard</a></li>
                        <li className="breadcrumb-item active" aria-current="page">Send Invite</li>
                    </ol>
                </nav>
            </div>
            <div className="col-md-4 small  text-right">
                <div className="row">
                    
                </div>
            </div>
            <div className="col-md-4 text-right">
                <a href="/employer/viewinvites" className="text-primary pr-3"> <i className="bi bi-plus"></i> View Invites</a> 
            </div>
        </div>
        <div className="row">
            <div className="col-md-6 m-auto pb-5">
                <div className="card  mb-4">
                    <div className="card-body">
                        <div className="col-md-6 bg-white m-auto shadow-sm p-4 ">
                            <form method="POST" onSubmit={handleSubmit} >
                                
                                <div className="input_fields_wrap"> 
                                    <div className="mb-2 col-md-12 mt-2">
                                        <label  className="form-label small" style={{ color: '#2D8BB4' }}><strong>User Email*</strong></label>
                                        <br/>
                                        <div className="mb-3">
                                            
                                            <input className="form-control file" type="email" onChange={handleChange} name="useremail" id="userEmail" />
                                            <div className="invalid-feedback small">{error}</div>
                                            <div className="alert-success small">{message}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-2 col-md-12 mt-5 text-center">
                                    <button type="button" className="btn btn-secondary ml-2"  >Cancel</button> <button type="submit" onClick={validateForm} className="btn btn-primary ml-2">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Sendinvite
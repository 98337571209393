import React,{useState,useEffect} from 'react'
import Modal from 'react-bootstrap/Modal';
import AuthUser from "../AuthUser";
import Viewnewdocument from './Viewnewdocument';
function Newemployeedocumentation(props) {
    const { http, getUser } = AuthUser();
    let userdetails = getUser();
    let usertype = props.logintype;
    let dashboard=`/${usertype}`;
    const [values,setValues]=useState({title:'',document:''});
    const [errors, setErrors] = useState({title: '',document: '',message:'' });
    const [message,setMessage]= useState();
    const [newempdocument, setNewempdocument] = useState([]);
    const [file, setFile] = useState(false);
    const [modalshow, setModalshow] = useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        setMessage('');   
    }
    const handleModalShow = (filename) =>{
        setModalshow(true);
        setFile(filename);
    } 
    function fetchItems(){
        http.get(`${props.logintype}/${props.apiurl}/${userdetails.email}`)
          .then((response) => {
            //console.log(response.data);
            setNewempdocument(response.data.newempdocuments);
          })
          .catch(function (error) {
            // handle error
            console.log(error.response.data.error);
          });
    }
    useEffect(() => {
        fetchItems();
    }, []);
    const handleDownload = (filename) =>{
        //console.log(filename);
        http.post(`${props.logintype}/downloadnewdocument/${userdetails.email}`,{filename:filename}, {
            responseType: 'blob',
        })
        .then((response) => {
            //console.log(response);
            //let filename = filename;
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url; //''
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        })
        .catch(function (error) {
            // handle error
            console.log('There was an error downloading the file!', error);
        });
    }
    const validateForm = () => {
        let isValid = true;
        const newErrors = { ...errors };
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        // Validate email
        if (!values.title.trim()) {
            newErrors.title = 'Title is required';
            isValid = false;
        } else {
            newErrors.title = '';
        }

        // Validate password
        if (!values.document.trim()) {
            newErrors.document = 'Document is required';
            isValid = false;
        } else {
            newErrors.password = '';
        }

        setErrors(newErrors);
        setMessage('');
        return isValid;
    };
    function handleChange(e){
        setMessage('');
        setValues({...values,[e.target.name]:e.target.value});
    }
    const handleSubmit = async(e) =>{
        e.preventDefault();
        let title = values.title;
        let document = e.target.document.files[0];
        const newErrors = { ...errors };
        
        if (validateForm()) {
          http.post(`${props.logintype}/addnewdocument/${userdetails.email}`,{title:title,document:document,userid:userdetails.id}).then((response)=>{
              //setToken(response.data.userdetail,response.data.token,usertype);
              //console.log(response.status);
              console.log(response.data.message);
              setValues({
                title: '',
                document: '',
                message: '',
              });
              e.target.reset();
              fetchItems();
              setMessage('Document added successfully!');
              //fetchItems();
              
          }).catch(function (error) {
            // handle error
            //console.log(error.response.data.error);
            if(error.response.status==403){
                newErrors.title = error.response.data?.error?.title;
                newErrors.document = error.response.data?.error?.document;
                setErrors(newErrors);
            }
            if(error.response.status==401){
                newErrors.message = error.response.data?.error;
                setErrors(newErrors);
            }
            //setsubmitted(false)
          })
        } else {
          // Form is not valid, display error messages
    
        }
    }
  return (
    <>
        <div className="container-fluid">
        <div className="col-md-6 m-auto p-0 pb-2 ">
            <div className='row'>
                <div className="col-md-6 text-left pull-left">
                    <h6 className="mb-2 font-weight-bold">New Employee Documentation</h6>
                </div>
                <div className="col-md-6 text-right pull-right">
                    <a href={dashboard} className="mb-2 font-weight-bold">Back</a>           
                </div>
            </div>
        </div>
        <div className="col-md-6 bg-white m-auto shadow-sm p-4 text-center" style={{ borderTop: '5px solid #DF8E2E' }}>
            <div className='row'>
                <div className="col-md-6 text-right pull-right">
                    &nbsp;
                </div>
                <div className="col-md-6 text-right pull-right">
                {props.logintype !=='applicant' && <a href="#" onClick={handleShow} className="btn btn-primary rounded-0"> <i className="bi bi-plus"></i> Add New</a> }
                </div>
            </div>
            <div className="row">                
                <div className="bootstrap-table">
                    <div className="fixed-table-toolbar"></div>
                    <div className="fixed-table-container">
                        <div className="fixed-table-body">
                            <table data-toggle="table" data-classes="table table-hover table-condensed" data-sort-name="Quality" data-sort-order="desc" id="Tabla" data-page-length="10" className="table table-hover table-condensed">

                                <tbody>
                                    {newempdocument.map(newempdocument => (
                                    <tr key={newempdocument.id}>
                                        <th data-sortable="true">{newempdocument.title}</th>
                                        <th data-sortable="true">
                                            <a href="#" onClick={() => handleModalShow(newempdocument.essdocument)}>View</a> 
                                            | <a href="#" onClick={() => handleDownload(newempdocument.essdocument)}>Download</a>
                                        </th>
                                    </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <strong>Note</strong> : Upload and Submit the completed I9 / W4 / Direct Deposit Authorization form along with void check in my documents. 

                    </div>
                </div>
            </div>
        </div>
        <Viewnewdocument show={modalshow} filename={file} foldertype="empselfdocs" close={() => setModalshow(false)} />
        <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter"><span className="pl-2"> Upload Document </span></Modal.Title>
            </Modal.Header>
            <form method="POST" onSubmit={handleSubmit}>
            <Modal.Body className='modal-body'>                
                <div className="input_fields_wrap">
                    <div className="mb-2 col-md-12">
                        <label className="form-label small" style={{ color: '#2D8BB4' }}><strong>Document Title*</strong></label>
                        <input className="form-control title" list="datalistOptions"  name="title" onChange={handleChange} placeholder="Enter Title" />
                        <input type="hidden" className="form-control title" list="datalistOptions"  name="documenttype" value={props.documenttype} />
                        <div className="invalid-feedback small">{errors.title}</div>
                    </div>
                    <div className="mb-2 col-md-12 mt-2">
                        <label className="form-label small" style={{ color: '#2D8BB4' }}><strong>Upload File*</strong></label>
                        <br />
                        <div className="mb-3">
                            <input className="form-control file" type="file" name="document" onChange={handleChange} accept=".docx,application/pdf" id="formFile" />
                            <div className="invalid-feedback small">{errors.document}</div>
                            <div className="invalid-feedback small">{errors.message}</div>
                            <div className="alert-success small">{message}</div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="mb-2 col-md-12 mt-5 text-center">
                    <button type="reset" className="btn btn-secondary ml-2" data-dismiss="modal">Cancel</button>
                    <button type="submit" className="btn btn-primary ml-2" onClick={validateForm}>Submit</button>
                </div>
            </Modal.Footer>
            </form>
        </Modal>
      </div>
    </>
        
  )
}

export default Newemployeedocumentation
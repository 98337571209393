import React,{useState,useEffect} from 'react'
import Modal from 'react-bootstrap/Modal';
import AuthUser from "../AuthUser";

function Eventslist(props) {
    const [values,setValues]=useState({eventname:'',eventdate:'',eventtime:''});
    const [errors, setErrors] = useState({eventname:'',eventdate:'',eventtime:''});
    const [message,setMessage]= useState();
    const [events,setEvents]=useState([]);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const { http, getUser } = AuthUser();
    let usertype=props.logintype;
    let userdetails = getUser();
    let dashboard=`/${usertype}`;
    const validateForm = () => {
        let isValid = true;
        const newErrors = { ...errors };
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        // Subject
        if (!values.eventname.trim()) {
            newErrors.eventname = 'Event name is required';
            isValid = false;
        } else {
            newErrors.eventname = '';
        }
        // eventdate
        if (!values.eventdate.trim()) {
            newErrors.eventdate = 'Event date is required';
            isValid = false;
        } else {
            newErrors.eventdate = '';
        }
        // File Upload
        if (!values.eventtime.trim()) {
            newErrors.eventtime = 'Event time is required';
            isValid = false;
        } else {
            newErrors.eventtime = '';
        }
        setErrors(newErrors);
        setMessage('');
        return isValid;
    };
    function handleChange(e){
        setMessage('');
        setValues({...values,[e.target.name]:e.target.value});
    }
    const handleSubmit = async(e) =>{
        e.preventDefault();
        let eventname = values.eventname;
        let eventdate = values.eventdate;
        let eventtime = values.eventtime;
        let message = '';
        const newErrors = { ...errors };
        
        if (validateForm()) {
          http.post(`employer/addevent/${userdetails.email}`,{eventname:eventname,eventdate:eventdate,eventtime:eventtime,userid:userdetails.id}).then((response)=>{
              //setToken(response.data.userdetail,response.data.token,usertype);
              //console.log(response.status);
              console.log(response.data.message);
              setValues({
                eventname: '',
                eventdate: '',
                eventtime: '',
              });
              e.target.reset();
              setMessage('Event added successfully!');
              fetchItems();
              
          }).catch(function (error) {
            // handle error
            //console.log(error.response.data.error);
            if(error.response.status==403){
                newErrors.eventname = error.response.data?.error?.eventname;
                newErrors.eventdate = error.response.data?.error?.eventdate;
                newErrors.eventtime = error.response.data?.error?.eventtime;
                setErrors(newErrors);
            }
            if(error.response.status==401){
                newErrors.message = error.response.data?.error;
                setErrors(newErrors);
            }
            //setsubmitted(false)
          })
        } else {
          // Form is not valid, display error messages
    
        }
    }
    //
    const fetchItems = async () => {
        http.get(`${usertype}/events/${userdetails.email}`)
        .then((response) => {
        //console.log(response.data);
        setEvents(response.data.events);
        })
        .catch(function (error) {
        // handle error
        console.log(error.response.data.error);
        });
      };
    useEffect(() => {
        fetchItems();
    }, []);
  return (
    <div className="container-fluid">
        <div className="col-md-8 m-auto p-0 pb-2 ">
            <div className='row'>
                <div className="col-md-6 text-left pull-left">
                    <h6 className="mb-2 font-weight-bold">Events</h6>
                </div>
                <div className="col-md-6 text-right pull-right">
                    <a href={dashboard} className="mb-2 font-weight-bold">Back</a>           
                </div>
            </div>
        </div>
        <div className="col-md-8 bg-white m-auto shadow-sm p-4" style={{ borderTop: '5px solid #DF8E2E' }}>
            <div className='row'>
                <div className="col-md-6 text-right pull-right">
                    &nbsp;
                </div>
                <div className="col-md-6 text-right pull-right">
                    {usertype==='employer' && <a href="#" onClick={handleShow} className="btn btn-primary rounded-0"> <i className="bi bi-plus"></i> Add Event</a>}
                    
                </div>
            </div>
            <table data-toggle="table" data-classes="table table-hover table-condensed"
                data-striped="true" data-sort-name="Quality" data-sort-order="desc" id="Tabla"
                className="Tabla table table-hover table-condensed" data-page-length='10'>
                <thead>
                    <tr>
                    {usertype ==='admin' && <th>Name</th>}
                        <th >Event Name</th>
                        <th data-sortable="true">Date and time</th>
                    </tr>
                </thead>
                <tbody>
                    {events.map(event =>(
                    <tr key={event.id}>
                        {usertype ==='admin' && <td>{event.name} {event.lastname}</td>}
                        <td>{event.eventname}</td>
                        <td>{event.eventdate} {event.eventtime}</td>
                    </tr>
                    ))

                    }
                </tbody>
            </table>
        </div>
        <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter"><span className="pl-2"> Add Event Detail </span></Modal.Title>
            </Modal.Header>
            <form method="POST" onSubmit={handleSubmit}>
            <Modal.Body className='modal-body'>                
                <div className="col-md-12 p-2 border customer_records" style={{ backgroundColor: '#F6F6F6' }}>
                    <div className="mb-3">
                        <input type="text" className="form-control  rounded-0 textOnly mt-2" id="validationTooltip01" name="eventname" onChange={handleChange} placeholder="Event name*" />
                        <div className="invalid-feedback small">{errors.eventname}</div>
                        <input type="date" className="form-control  rounded-0 textOnly mt-2" id="validationTooltip01" name="eventdate" onChange={handleChange} placeholder="Event Date *" />
                        <div className="invalid-feedback small">{errors.eventdate}</div>
                        <input type="time" className="form-control  rounded-0 textOnly mt-2" id="validationTooltip01" name="eventtime" onChange={handleChange} placeholder="Event Time *" />
                        <div className="invalid-feedback small">{errors.eventtime}</div>
                        <div className="alert-success small">{message}</div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="mb-2 col-md-12 mt-5 text-center">
                    <button type="reset" className="btn btn-secondary ml-2" data-dismiss="modal">Cancel</button>
                    <button type="submit" className="btn btn-primary ml-2" onClick={validateForm}>Submit</button>
                </div>
            </Modal.Footer>
            </form>
        </Modal>
    </div>
  )
}

export default Eventslist
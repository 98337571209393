import React,{useState,useEffect} from 'react'
import AuthUser from '../AuthUser';
import Doc from '../images/doc.png';
import Pdf from '../images/pdf.png';
import Dropdown from "react-bootstrap/Dropdown";
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import Viewnewdocument from './Viewnewdocument';

function Myreferrals() {
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
        href=""
        ref={ref}
        onClick={e => {
        e.preventDefault();
        onClick(e);
        }}
    >
        {children}
        <span className="threedots" />
    </a>
    ));
  const { http, getUser } = AuthUser();
  let userdetails = getUser();
  let usertype = 'applicant';
  let dashboard=`/${usertype}`;
  const [file, setFile] = useState(false);
  const [modalshow, setModalshow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedid, setSelectedid] = useState(null);
  const [myreferrals, setMyreferrals] = useState([]);
  const [values,setValues]=useState({firstname:'',lastname:'',contactno:'',email:'',document:''});
  const [errors, setErrors] = useState({firstname:'',lastname:'',contactno:'',email:'',document:''});
  const [message,setMessage]= useState();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const validateForm = () => {
      let isValid = true;
      const newErrors = { ...errors };
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const alphaRegex = /^[A-Za-z]+$/;
      const numRegex = /^[0-9\b]+$/;
      // Subject
      // Message
      if (!values.firstname.trim()) {
          newErrors.firstname = 'First name is required';
          isValid = false;
      } else if(!alphaRegex.test(values.firstname)){
        newErrors.firstname='Only Characters allowed';
        isValid = false;
      }else {
          newErrors.firstname = '';
      }
      // File Upload
      if (!values.lastname.trim()) {
          newErrors.lastname = 'Last name is required';
          isValid = false;
      } else if(!alphaRegex.test(values.lastname)){
        newErrors.lastname='Only Characters allowed';
        isValid = false;
      }else {
          newErrors.lastname = '';
      }
      if (!values.contactno.trim()) {
          newErrors.contactno = 'Mobile no is required';
          isValid = false;
      } else if(!numRegex.test(values.contactno)){
            newErrors.contactno='Only numbers allowed';
            isValid = false;
      }else {
          newErrors.contactno = '';
      }
      if (!values.email.trim()) {
          newErrors.email = 'Email is required';
          isValid = false;
      } else {
          newErrors.email = '';
      }
      if (!values.document.trim()) {
          newErrors.document = 'Document is required';
          isValid = false;
      } else {
          newErrors.document = '';
      }
      setErrors(newErrors);
      setMessage('');
      return isValid;
  };
    function handleChange(e){
        setMessage('');
        setValues({...values,[e.target.name]:e.target.value});
    }
    const handleSubmit = async(e) =>{
        e.preventDefault();
        let firstname = values.firstname;
        let lastname = values.lastname;
        let contactno = values.contactno;
        let email = values.email;
        let document = e.target.document.files[0];
        let message = '';
        const newErrors = { ...errors };
        
        if (validateForm()) {
          http.post(`applicant/addreferral/${userdetails.email}`,{firstname:firstname,lastname:lastname,contactno:contactno,email:email,file:document,userid:userdetails.id}).then((response)=>{
              //setToken(response.data.userdetail,response.data.token,usertype);
              //console.log(response.status);
              console.log(response.data.message);
              setValues({
                firstname: '',
                lastname: '',
                telephone: '',
                mobileno: '',
                email: '',
                message: '',
              });
              e.target.reset();
              setMessage('My referral added successfully!');
              fetchItems();
              
          }).catch(function (error) {
            // handle error
            //console.log(error.response.data.error);
            if(error.response.status==403){
                newErrors.firstname = error.response.data?.error?.firstname;
                newErrors.lastname = error.response.data?.error?.lastname;
                newErrors.contactno = error.response.data?.error?.contactno;
                newErrors.email = error.response.data?.error?.email;
                newErrors.document = error.response.data?.error?.document;
                setErrors(newErrors);
            }
            if(error.response.status==401){
                newErrors.message = error.response.data?.error;
                setErrors(newErrors);
            }
            //setsubmitted(false)
          })
        } else {
          // Form is not valid, display error messages
    
        }
    }
  const handleModalShow = (filename) =>{
      setModalshow(true);
      setFile(filename);
  }
  
  function fetchItems(){
    http.get(`applicant/myreferrals/${userdetails.email}`)
    .then((response) => {
      //console.log(response.data);
      setMyreferrals(response.data.myreferrals);
    })
    .catch(function (error) {
      // handle error
      console.log(error.response.data.error);
    });
  }
  useEffect(() => {
    fetchItems();
  }, []);
  //Delete
  const handleDelete = () =>{
      http.post(`applicant/deletereferral/${userdetails.email}`,{documentid:selectedid})
      .then((response) => {
          //console.log(response); 
          //Fetch data function
          fetchItems();
          handleCloseModal();            
      })
      .catch(function (error) {
          // handle error
          console.log(error.response.data.error);
      });
  }
  const handleShowModal = (id) => {
    setSelectedid(id);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setSelectedid(null);
    setShowModal(false);
  };
  //End of Delete
  return (
    <div className="container-fluid">
        <div className="col-md-9 m-auto p-0 pb-2 ">
            <div className='row'>
                <div className="col-md-6 text-left pull-left">
                    <h6 className="mb-2 font-weight-bold">My Referrals</h6>
                </div>
                <div className="col-md-6 text-right pull-right">
                    <a href={dashboard} className="mb-2 font-weight-bold">Back</a>           
                </div>
            </div>
        </div>
        <div className="col-md-9 bg-white m-auto shadow-sm p-4 text-center" style={{ borderTop: '5px solid #DF8E2E' }}>
          <div className='row'>
              <div className="col-md-6 text-right pull-right">
                  &nbsp;
              </div>
              <div className="col-md-6 text-right pull-right">
                  <a href="#" onClick={handleShow} className="btn btn-primary rounded-0"> <i className="bi bi-plus"></i> Add Referral</a>
              </div>
          </div>
          <table data-toggle="table" data-classes="table table-hover table-condensed" data-striped="true" data-sort-name="Quality" data-sort-order="desc" id="Tabla" className="Tabla table table-hover table-condensed" data-page-length='10'>
            <thead>
              <tr>
                <th data-sortable="true">First Name</th>
                <th data-sortable="true">Last Name</th>
                <th data-sortable="true">Email</th>
                <th data-sortable="true">Tel#</th>
                <th data-sortable="true"><i className="bi bi-paperclip"></i></th>
                <th data-sortable="true">Date &amp; Time</th>
                <th class="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
                {myreferrals.map(myreferral => (
                <tr key={myreferral.id}>
                    <td className="text-center">{ myreferral.firstname}</td>
                    <td className="text-center">{ myreferral.lastname}</td>
                    <td>{ myreferral.email}</td>
                    <td>{ myreferral.contactno}</td>
                    <td><a href="#" onClick={() => handleModalShow(myreferral.file_name)} ><img src={myreferral.file_name.split('.').pop() == 'pdf' ? Pdf : Doc} />{myreferral.old_name}</a></td>
                    <td>{ myreferral.created_at}</td>
                    <td>
                      <Dropdown>
                          <Dropdown.Toggle as={CustomToggle} />
                          <Dropdown.Menu size="sm" title="">
                              <Dropdown.Item href="#" onClick={()=>handleShowModal(myreferral.id)} >Delete</Dropdown.Item>                                                                    
                          </Dropdown.Menu>
                      </Dropdown>
                    </td>
                </tr>
                ))}
            </tbody>
          </table>
        </div>
        <Viewnewdocument show={modalshow} filename={file} foldertype="empselfreferralcv" close={() => setModalshow(false)} />
        <Modal size="md" aria-labelledby="contained-modal-title-vcenter" show={showModal} onHide={handleCloseModal} centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Delete</Modal.Title>
            </Modal.Header>
            <Modal.Body>                
                <div className="input_fields_wrap">
                    <div className="mb-2 col-md-12">
                        <p style={{ color: '#2D8BB4' }}><strong>Are you sure you want to delete ?</strong></p>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="mb-2 col-md-12 text-center">
                    <button type="button" className="btn btn-secondary ml-2" onClick={handleCloseModal}>No</button> 
                    <button type="submit" onClick={handleDelete} className="btn btn-primary ml-2">Yes</button>
                </div>
            </Modal.Footer>
        </Modal>
        <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter"><span className="pl-2"> Add Employee Referral</span></Modal.Title>
            </Modal.Header>
            <form method="POST" onSubmit={handleSubmit}>
            <Modal.Body className='modal-body'>                
                <div className="col-md-12 p-2 border customer_records" style={{ backgroundColor: '#F6F6F6' }}>
                    <div className="mb-3">
                       <input type="text" className="form-control  rounded-0 textOnly mt-2" id="validationTooltip01" name="firstname" onChange={handleChange} placeholder="First Name *" />
                        <div className="invalid-feedback small">{errors.firstname}</div>
                        <input type="text" className="form-control  rounded-0 textOnly mt-2" id="validationTooltip01" name="lastname" onChange={handleChange} placeholder="Last Name *" />
                        <div className="invalid-feedback small">{errors.lastname}</div>                        
                        <input type="text" className="form-control  rounded-0 textOnly mt-2" id="validationTooltip01" name="email" onChange={handleChange} placeholder="Email *" />
                        <div className="invalid-feedback small">{errors.email}</div>
                        <input type="text" className="form-control  rounded-0 textOnly mt-2" id="validationTooltip01" name="contactno" onChange={handleChange} placeholder="Mobile Number *" />
                        <div className="invalid-feedback small">{errors.contactno}</div>
                        <div className="row">
                          <div className="col-lg-12 mt-4">
                              <div className="form-group">
                                  <input className="form-control file" type="file" name="document"  accept=".docx,application/pdf" id="formFile" onChange={handleChange} />
                                  <div className="invalid-feedback small">{errors.document}</div>
                              </div>
                          </div>
                        </div>
                        <div className="alert-success small">{message}</div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="mb-2 col-md-12 mt-5 text-center">
                    <button type="reset" className="btn btn-secondary ml-2" data-dismiss="modal">Cancel</button>
                    <button type="submit" className="btn btn-primary ml-2" onClick={validateForm}>Submit</button>
                </div>
            </Modal.Footer>
            </form>
        </Modal>
    </div>
  )
}

export default Myreferrals
import React from 'react'

function Referralpolicy() {
  let usertype = 'applicant';
    let dashboard=`/${usertype}`;
  return (
    <div className='container'>
        <div className="col-md-12 p-0 pb-2 ">
            <div className='row'>
                <div className="col-md-12 text-right pull-right">
                    <a href={dashboard} className="mb-2 font-weight-bold">Back</a>           
                </div>
            </div>
        </div>
        <div className="col-md-12 border p-4 bg-white" style={{ height: '700px', overflow: 'auto' }}>
                    <div className="row border-bottom">
                        <div className="col-md-12 text-center pb-3 text-uppercase">
                            <h5 >
                                <strong>Employee Referral Policy</strong>
                            </h5>
                        </div>
                    </div>
                    <h6 style={{color:'#2B91BD'}} className="mt-4">Introduction</h6>
                    <p>




</p><p>“Transnational Software Services Inc” Employee Referral Program is a recruiting tool designed to encourage “Transnational Software Services Inc”employees to refer qualified candidates to fill open positions at “Transnational Software Services Inc”.</p>

<p></p>
                    <h6 style={{color:'#2B91BD'}} className="mt-4">Eligibility</h6>
                    <p>




</p><p>Current employees of “Transnational Software Services Inc” are eligible to make referrals through this program. In case of multiple referrals, the first employee to submit the referral will be recognized as the referring employee. The candidate who is referred to the open job position should not be a current employee of the company.</p>

<p></p>
                    <h6 style={{color:'#2B91BD'}}>Incentives Offered</h6>
                    <p>




</p><p>If the new employee, who was hired through employee referral program, stays with the company for at least 90_days or more, then the referring employee receives a special bonus in the amount of $1000. There is no limit to the number of referrals an employee can make or the number of referral bonuses an employee may receive.</p>

<p></p>
                    <h6 style={{color:'#2B91BD'}}>Referral Procedure</h6>
                    <p>




</p><p>Candidates can be referred to any open position by contacting the HR directly. If the candidate referred by the employee is offered and accepts the position, HR will contact the employee who made the referral directly. &nbsp;Upon meeting the requirements the candidate must apply with the HR Department for the referral fees by providing the candidate name.</p>

<p></p>
                </div>
    </div>
    
  )
}

export default Referralpolicy
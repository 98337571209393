import React,{useState,useEffect} from 'react'
import AuthUser from '../AuthUser';
import Dropdown from "react-bootstrap/Dropdown";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Pagination from 'react-bootstrap/Pagination';
import PageItem from 'react-bootstrap/PageItem'
function Applicantslist(props) {
    let usertype = props.logintype;
    let pageview = props.pageview;
    let doctype= pageview === 'IT Consultants' ? 'DOCS' : pageview === 'GC Consultants' ? 'GCDOCS' : pageview === 'Form I9 info' ? 'FORM9DOCS' : 'DOCS';
    let apiurl = props.apiurl;
    const [applicants, setApplicants] = useState([])
    const [applicantcount, setApplicantcount] = useState(0)
    const [filter, setFilter] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [selectedid, setSelectedid] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [viewcount,setViewcount]=useState([]);
    const [appstatuslist, setAppstatuslist] = useState([]);
    const[links,setLinks]=useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const { http, getUser } = AuthUser();
    let active = 2;
    let items = [];
    let userdetails = getUser();
    let dashboard=`/${usertype}`;
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a
          href=""
          ref={ref}
          onClick={e => {
            e.preventDefault();
            onClick(e);
          }}
        >
          {children}
          <span className="threedots" />
        </a>
      ));
    function fetchItems(){
        http.get(`${usertype}/${apiurl}/${userdetails.email}?page=${currentPage}&search=${filter}`)
        .then((response) => {
            console.log(response.data);
            setApplicants(response.data.applicantslist.data);
            setApplicantcount(response.data.applicantslist.total);
            setFilteredData(response.data.applicantslist.data);
            setLinks(response.data.applicantslist.links);
            setTotalPages(response.data.applicantslist.last_page);
            if(usertype !== 'attorney'){

                setViewcount(response.data.viewcount);
            }
            setAppstatuslist(response.data.appstatus);
        })
        .catch(function (error) {
            // handle error
            console.log(error.response.data.error);
        });
    }
    useEffect(() => {
        fetchItems();
    }, [currentPage, filter]);
    useEffect(() => {
    // Set up an interval to increment the count every second
    
    const intervalId = setInterval(() => {
        if(usertype !== 'attorney'){
            http.get(`${usertype}/viewcount/${userdetails.email}`)
            .then((response) => {
                setViewcount(response.data.viewcount);
            })
            .catch(function (error) {
                // handle error
                //console.log(error.response.data.error);
            });
        }
        
        
    }, 100000);

    // Cleanup function to clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
    }, [100000]); 
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        console.log(pageNumber);
        fetchItems();
      };
    const handleFilterChange = (e) => {
        const value = e.target.value.toLowerCase();
        setFilter(value);
        setCurrentPage(1);
        fetchItems();
        /*
        const filtered = applicants.filter(item =>
            item.name.toLowerCase().includes(value) || item.lastname.toLowerCase().includes(value) || item.email.toLowerCase().includes(value) || item.phone.toLowerCase().includes(value)
            // Add more conditions based on your data structure
        );

        setFilteredData(filtered);
        */
        
    };
    const handleDelete = () => {
        http.post(`${usertype}/deleteapplicant`,{applicantid:selectedid})
        .then((response) => {
            //console.log(response); 
            fetchItems();
            handleCloseModal();           
        })
        .catch(function (error) {
            // handle error
            console.log(error.response.data.error);
        });
    };

    const handleShowModal = (id) => {
        setSelectedid(id);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setSelectedid(null);
        setShowModal(false);
    };
    const handleCheckboxChange = async (taskId, isChecked) => {
        http.get(`employer/gcStatus?id=${taskId}+&status=${isChecked}`)
        .then((response) => {
            fetchItems();
            console.log(response.data);
        })
        .catch(function (error) {
            // handle error
            console.log(error.response.data.error);
        });
    };
    const makeApplicant = (val, id) =>{
        http.get(`/${usertype}/changeApplicantstatus?applicantid=${id}&statusval=${val}&status=makestatus`)
        .then((response) => {
            fetchItems();
        })
        .catch(function (error) {
            // handle error
            console.log(error.response.data.error);
        });
    }
    const applyStatus = (val, id) =>{
        http.get(`/${usertype}/actionUpdate?applicantid=${id}&statusval=${val}&status=makestatus`)
        .then((response) => {
            fetchItems();
        })
        .catch(function (error) {
            // handle error
            console.log(error.response.data.error);
        });
    }
    const makeAppstatus = (val, id) =>{
        http.get(`/${usertype}/applicantstatus?applicantid=${id}&statusval=${val}`)
        .then((response) => {
            fetchItems();
        })
        .catch(function (error) {
            // handle error
            console.log(error.response.data.error);
        });
    }
  return (
    <div className="container-fluid"> 
        <div className="row mb-2 ">
            <div className="col-md-6">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb p-0">
                        <li className="breadcrumb-item "><a href={dashboard}>Dashboard</a></li>
                        <li className="breadcrumb-item active" aria-current="page">{pageview}</li>
                    </ol>
                </nav>
            </div>
            <div className="col-md-4 small  text-right">
                <div className="row">
                    <input type="text" name="email" value={filter}
                onChange={handleFilterChange} className="form-control searchEmail" placeholder="Search by First name, last name, phone or email id..." />
                </div>
            </div>
            <div className="col-md-2 small  text-right">
                <h6 className="font-weight-bold">H1B applicants :<span className="text-primary">{applicantcount}</span></h6>
            </div>
        </div>
        <div className="row">
            <div className="col-md-12 pb-5">
                <div className="card  mb-4">
                    <div className="card-body">
                        <div className="table-responsive">
                            <table  data-toggle="table" data-classes="table table-hover table-condensed" 
                                    data-striped="true"  className="table table-hover table-condensed table-striped dataTable no-footer" data-sort-name="Quality"  data-sort-order="desc" id="Tabla" data-page-length='10'>
                                <thead>
                                    <tr>
                                        <th data-sortable="true">First Name</th>
                                        <th>Last Name</th>
                                        <th>Email</th>
                                        <th className="text-center">Tel #</th>
                                        <th className="text-center"><i className="bi bi-paperclip"></i> </th>
                                        {doctype ==='GCDOCS' && <th data-sortable="true" className="text-center">Process</th> }
                                        {doctype !=='FORM9DOCS' && <th data-sortable="true" className="text-center">Country</th> }
                                        
                                        {doctype !=='FORM9DOCS' && <th data-sortable="true" className="text-center">Applicant Status</th> }
                                        <th data-sortable="true">Registered  Date / Time</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredData.map(applicant =>(
                                        <tr key={applicant.id}>
                                            <td>
                                                
                                                <div className="row">
                                                    <div className="col-8">
                                                        {applicant.name} 
                                                    </div> 
                                                    {doctype !=='GCDOCS' &&
                                                    <div className='col-3'>
                                                    { (applicant.status == "A") ? 
                                                        <span style={{ color:'#0099cc' }} className="pull-right">Active</span> :
                                                        <span style={{ color:'#cc3300' }} className="pull-right">Inactive</span>
                                                    }
                                                    </div>
                                                    }   
                                                    {doctype !=='GCDOCS' &&                                                
                                                    <div className="col-1">
                                                        <Dropdown>
                                                            <Dropdown.Toggle as={CustomToggle} />
                                                            <Dropdown.Menu size="sm" title="">
                                                            <Dropdown.Item href="javascript:void(0);" onClick={() => applyStatus('I',applicant.id)}>Inactive</Dropdown.Item>
                                                            <Dropdown.Item href="javascript:void(0);" onClick={() => applyStatus('A',applicant.id)}>Active</Dropdown.Item>
                                                            { usertype === 'admin' && <Dropdown.Item href="#?" onClick={() => handleShowModal(applicant.id)}>Delete</Dropdown.Item> }
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                    }
                                                </div>
                                            </td>
                                            <td>{applicant.lastname}</td>
                                            <td>{applicant.email}</td>
                                            <td className="text-center">+1 {applicant.phone}</td>
                                            <td className='text-center'><a href={`/${usertype}/mydocs/${applicant.id}/${doctype}`}>View Docs { (viewcount.hasOwnProperty(applicant.id)) ? ((viewcount[applicant.id] ===  1) ? <span className="badge badge-danger">New</span> : '') : '' } </a> </td>
                                            { doctype ==='GCDOCS' && <td className="text-center"><Form.Check type="switch" id={applicant.id} checked={applicant.gcstatus} onChange={(e) => handleCheckboxChange(applicant.id, e.target.checked) } /></td> }
                                            {doctype !=='FORM9DOCS' &&  <td className="text-center">USA</td> }
                                            {doctype !=='FORM9DOCS' && 
                                            <td className="text-center">
                                                {doctype ==='GCDOCS' ?
                                                <div className="row">
                                                    <div className="col-9">{applicant.user_status }</div>                                                    
                                                    <div className="col-3">
                                                        <Dropdown>
                                                            <Dropdown.Toggle as={CustomToggle} />
                                                            <Dropdown.Menu size="sm" title="">
                                                                <Dropdown.Item href="javascript:void(0);" onClick={() => makeApplicant('Initiated',applicant.id)} >Initiated</Dropdown.Item>
                                                                <Dropdown.Item href="javascript:void(0);" onClick={() => makeApplicant('Hold',applicant.id)} >Hold</Dropdown.Item>
                                                                <Dropdown.Item href="javascript:void(0);" onClick={() => makeApplicant('Declined',applicant.id)} >Declined</Dropdown.Item>
                                                                <Dropdown.Item href="javascript:void(0);" onClick={() => makeApplicant('Approved',applicant.id)} >Approved</Dropdown.Item>
                                                                <Dropdown.Item href="javascript:void(0);" onClick={() => makeApplicant('Rfe',applicant.id)} >Rfe</Dropdown.Item>
                                                                <Dropdown.Item href="javascript:void(0);" onClick={() => makeApplicant('PREWAGES',applicant.id)} >PREWAGES</Dropdown.Item>
                                                                <Dropdown.Item href="javascript:void(0);" onClick={() => makeApplicant('Ads',applicant.id)} >Ads</Dropdown.Item>
                                                                <Dropdown.Item href="javascript:void(0);" onClick={() => makeApplicant('Audit',applicant.id)} >Audit</Dropdown.Item>
                                                                <Dropdown.Item href="javascript:void(0);" onClick={() => makeApplicant('Perm',applicant.id)} >Perm</Dropdown.Item>
                                                                <Dropdown.Item href="javascript:void(0);" onClick={() => makeApplicant('I140',applicant.id)} >I140</Dropdown.Item>
                                                                <Dropdown.Item href="javascript:void(0);" onClick={() => makeApplicant('485',applicant.id)} >485</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                                :
                                                <div className="row">
                                                    <div className="col-9">{applicant.application_status }</div>                                                    
                                                    <div className="col-3">
                                                        <Dropdown>
                                                            <Dropdown.Toggle as={CustomToggle} />
                                                            <Dropdown.Menu size="sm" title="">
                                                            { appstatuslist.map(appstatus =>(
                                                                <Dropdown.Item key={appstatus.id} href="javascript:void(0);" onClick={() => makeAppstatus(appstatus.status_name,applicant.id)} >{appstatus.status_name}</Dropdown.Item>
                                                            ))}
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                                }
                                            </td>
                                            }
                                            <td >{applicant.created_at}</td>
                                        </tr>
                                    ))

                                    }
                                </tbody>
                            </table>                
                        </div>                        
                    </div>                    
                </div>
                <Pagination size="sm">
                    {links.map(link =>(
                        <Pagination.Item active={link.label === active} onClick={() => handlePageChange(link.label)} >
                            {link.url !== null && link.label}                    
                        </Pagination.Item>
                    ))}
                </Pagination> 
            </div>
        </div>
        <Modal size="md" aria-labelledby="contained-modal-title-vcenter" show={showModal} onHide={handleCloseModal} centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Delete</Modal.Title>
            </Modal.Header>
            <Modal.Body>                
                <div className="input_fields_wrap">
                    <div className="mb-2 col-md-12">
                        <p style={{ color: '#2D8BB4' }}><strong>Are you sure you want to delete ?</strong></p>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="mb-2 col-md-12 text-center">
                    <button type="button" className="btn btn-secondary ml-2" onClick={handleCloseModal}>No</button> 
                    <button type="submit" onClick={handleDelete} className="btn btn-primary ml-2">Yes</button>
                </div>
            </Modal.Footer>
        </Modal>
    </div>
  )
}

export default Applicantslist
import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import AuthUser from './AuthUser';
import Homenav from './Navbar/Homenav';
import ApplicantNav from './Navbar/ApplicantNav';
import AttorneyNav from './Navbar/AttorneyNav';
import EmployerNav from './Navbar/EmployerNav';
import AdminNav from './Navbar/AdminNav';
import Footer from './Common/Footer';

function App() {
  
    const {getUsertype,getToken} = AuthUser();
    let usertype = getUsertype()== 'applicant' ? <ApplicantNav /> : getUsertype()== 'attorney' ? <AttorneyNav /> : getUsertype()== 'employer' ? <EmployerNav /> : getUsertype()== 'admin' ? <AdminNav /> : <Homenav />;
    if(!getToken()){
      return (
        <>
          <Homenav />
          <Footer />
        </>
      );
    }
    return (
        <>          
          { usertype }
          <Footer />
        </>
    );
}

export default App;

import React,{useState,useEffect} from 'react';
import { useParams } from 'react-router-dom';
import Doc from '../images/doc.png'
import Modal from 'react-bootstrap/Modal';
import AuthUser from "../AuthUser";

function Timesheetreport() {
    const { http, getUser } = AuthUser();
    let userdetails = getUser();
    const {id} = useParams();
    const [timesheetreport, setTimesheetreport] = useState([]);
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');
    let usertype = 'applicant';
    let dashboard=`/${usertype}`;
    const now = new Date();
    const current = now.getFullYear();
    const previous = current - 1;
    useEffect(() => {
        http.get(`applicant/timesheetreport/${userdetails.email}?id=${id}`)
          .then((response) => {
            console.log(response.data);
            setTimesheetreport(response.data.timesheetreports);
            setMonth(response.data.reportof);
            setYear(response.data.year);
          })
          .catch(function (error) {
            // handle error
            console.log(error.response.data.error);
          });
      }, []);
  return (
    <div className="container-fluid">
        <div className="col-md-9 m-auto p-0 pb-2 ">
            <div className='row'>
                <div className="col-md-6 text-left pull-left">
                    <h6 className="mb-2 font-weight-bold">Timesheets {month} {year}</h6>
                </div>
                <div className="col-md-6 text-right pull-right">
                    <a href="/applicant/timesheet" className="mb-2 font-weight-bold">Back</a>           
                </div>
            </div>
        </div>
        <div className="col-md-9 bg-white m-auto shadow-sm p-4 text-center" style={{ borderTop: '5px solid #DF8E2E' }}>
            <table data-toggle="table" data-classes="table table-hover table-condensed" data-striped="true" data-sort-name="Quality" data-sort-order="desc" id="Tabla" className="Tabla table table-hover table-condensed" data-page-length='10'>
                <thead>
                    <tr>
                        <th data-sortable="true">Project Type</th>
                        <th data-sortable="true">Project/Client Name</th>
                        <th data-sortable="true">Month</th>
                        <th data-sortable="true">Year</th>
                        <th data-sortable="true">Working Hours</th>
                        <th data-sortable="true"><i className="bi bi-paperclip"></i></th>
                        <th data-sortable="true">Status</th>
                        <th data-sortable="true">Submitted Date and Time</th>
                    </tr>
                </thead>
                <tbody>
                    { timesheetreport.map(timesheetreport =>(
                        <tr>
                            <td >{timesheetreport.type}</td>
                            <td >{timesheetreport.projecttitle}</td>
                            <td >{timesheetreport.month}</td>
                            <td >{timesheetreport.year}</td>
                            <td >{timesheetreport.workhrs}</td>
                            <td >{timesheetreport.file_name}</td>
                            <td >{  (timesheetreport.status == 'P')  ? 'Pending' : 
                                    ((timesheetreport.status == 'A') ? 'Approved' : 
                                     (timesheetreport.status == 'R') ? 'Rejected' : 
                                     (timesheetreport.status == 'D') ? 'Dispute' : 'Deletion under Process' )
                                        
                                            
                            }</td>
                            <td >{timesheetreport.created_at}</td>
                        </tr>
                    ))}
                    
                </tbody>
            </table>
        </div>
    </div>
  )
}

export default Timesheetreport
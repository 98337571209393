import React,{useState,useEffect} from 'react'
import Modal from 'react-bootstrap/Modal';
import AuthUser from "../AuthUser";
import PhoneInput from "react-phone-input-2";

function Employees() {
    let usertype = 'employer';
    let dashboard=`/${usertype}`;
    const [values,setValues]=useState({firstname:'',lastname:'',email:'',phone:'',designation:'',country:'USA',state:'',gender:'',education:'',workexperience:'',dateofjoin:''});
    const [errors, setErrors] = useState({firstname:'',lastname:'',email:'',phone:'',designation:'',country:'',state:'',gender:'',education:'',workexperience:'',dateofjoin:''});
    const [message,setMessage]= useState();
    const [states,setStates]=useState([]);
    const [employees,setEmployees]=useState([]);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        setMessage('');
    }
    const { http, getUser } = AuthUser();
    const [phoneNumber, setPhoneNumber] = useState();
    let userdetails = getUser();
    const handlePhoneChange = (phone, data) => {
      // Handle phone number change
      console.log(data);
      //console.log(data.dialCode);
      //console.log(value.target.phone.value)
      //console.log(value.slice(data.dialCode.length));
      console.log(phone)
      //setCountrycode(data.countryCode);
      //setCountry(data.dialCode);
      //let val = phone.slice(data.dialCode.length);
      setPhoneNumber(phone);
      const phoneNumberPattern = /^\+?[1-9]\d{1,14}$/;
  
      phoneNumberPattern.test(phone);
      setValues({...values,['phone']:phone})
    };
    const validateForm = () => {
        let isValid = true;
        const newErrors = { ...errors };
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const alphaRegex = /^[A-Za-z]+$/;
        const numRegex = /^[0-9\b]+$/;
        // Subject
        if (!values.firstname.trim()) {
            newErrors.firstname = 'First name is required';
            isValid = false;
        }else if(!alphaRegex.test(values.firstname)){
            newErrors.firstname='Only Characters allowed';
            isValid = false;
        }else {
            newErrors.firstname = '';
        }
        // eventdate
        if (!values.lastname.trim()) {
            newErrors.lastname = 'Last name is required';
            isValid = false;
        } else if(!alphaRegex.test(values.lastname)){
            newErrors.lastname='Only Characters allowed';
            isValid = false;
        }else {
            newErrors.lastname = '';
        }
        // Validate email
        if (!values.email.trim()) {
            newErrors.email = 'Email is required';
            isValid = false;
        } else  if(!emailRegex.test(values.email)){
            newErrors.email = 'Email not valid';
            isValid = false;
        }else {
            newErrors.email = '';
        }
        // Subject
        if (!values.phone.trim()) {
            newErrors.phone = 'Phone is required';
            isValid = false;
        } else if(!numRegex.test(values.phone)){
            newErrors.lastname='Only numbers allowed';
            isValid = false;
        }else {
            newErrors.phone = '';
        }
        // eventdate
        if (!values.designation.trim()) {
            newErrors.designation = 'Deisgnation is required';
            isValid = false;
        } else {
            newErrors.designation = '';
        }
        // File Upload
        if (!values.country.trim()) {
            newErrors.country = 'Country is required';
            isValid = false;
        } else {
            newErrors.country = '';
        }
        // Subject
        if (!values.state.trim()) {
          newErrors.state = 'State is required';
          isValid = false;
      } else {
          newErrors.state = '';
      }
      // eventdate
      if (!values.gender.trim()) {
          newErrors.gender = 'Gender is required';
          isValid = false;
      } else {
          newErrors.gender = '';
      }
      // File Upload
      if (!values.education.trim()) {
          newErrors.education = 'Education is required';
          isValid = false;
      } else {
          newErrors.education = '';
      }
      // Subject
      if (!values.workexperience.trim()) {
        newErrors.workexperience = 'Work Experience is required';
        isValid = false;
    } else {
        newErrors.workexperience = '';
    }
    // eventdate
    if (!values.dateofjoin.trim()) {
        newErrors.dateofjoin = 'Date of join is required';
        isValid = false;
    } else {
        newErrors.dateofjoin = '';
    }
        setErrors(newErrors);
        setMessage('');
        return isValid;
    };
    function handleChange(e){
        setMessage('');
        setValues({...values,[e.target.name]:e.target.value});
    }
    const handleSubmit = async(e) =>{
        e.preventDefault();
        let firstname = values.firstname;
        let lastname = values.lastname;
        let email = values.email;
        let phone = values.phone;
        let designation = values.designation;
        let country = values.country;
        let state = values.state;
        let gender = values.gender;
        let education = values.education;
        let workexperience = values.workexperience;
        let dateofjoin = values.dateofjoin;
        let message = '';
        const newErrors = { ...errors };
        
        if (validateForm()) {
          http.post(`employer/addemployee/${userdetails.email}`,{firstname:firstname,lastname:lastname,email:email,phone:phone,designation:designation,country:country,state:state,gender:gender,education:education,workexperience:workexperience,dateofjoin:dateofjoin,userid:userdetails.id}).then((response)=>{
              //setToken(response.data.userdetail,response.data.token,usertype);
              //console.log(response.status);
              console.log(response.data.message);
              setValues({
                firstname:'',lastname:'',email:'',phone:'',designation:'',country:'',state:'',gender:'',education:'',workexperience:'',dateofjoin:''
              });
              e.target.reset();
              setMessage('Employee added successfully!');
              fetchItems();
              
          }).catch(function (error) {
            // handle error
            //console.log(error.response.data.error);
            if(error.response.status==403){
                newErrors.firstname = error.response.data?.error?.firstname;
                newErrors.lastname = error.response.data?.error?.lastname;
                newErrors.email = error.response.data?.error?.email;
                newErrors.phone = error.response.data?.error?.phone;
                newErrors.designation = error.response.data?.error?.designation;
                newErrors.country = error.response.data?.error?.country;
                newErrors.state = error.response.data?.error?.state;
                newErrors.gender = error.response.data?.error?.gender;
                newErrors.education = error.response.data?.error?.education;
                newErrors.workexperience = error.response.data?.error?.workexperience;
                newErrors.dateofjoin = error.response.data?.error?.dateofjoin;
                setErrors(newErrors);
            }
            if(error.response.status==401){
                newErrors.message = error.response.data?.error;
                setErrors(newErrors);
            }
            //setsubmitted(false)
          })
        } else {
          // Form is not valid, display error messages
    
        }
    }
    //
    const fetchItems = async () => {
        http.get(`employer/employeelist/${userdetails.email}`)
        .then((response) => {
          //console.log(response.data);
          setEmployees(response.data.employees);
          setStates(response.data.states);
        })
        .catch(function (error) {
        // handle error
        console.log(error.response.data.error);
        });
      };
    useEffect(() => {
        fetchItems();
    }, []);
  return (
    <div className="container-fluid">
        <div className="col-md-10 m-auto p-0 pb-2 ">
            <div className='row'>
                <div className="col-md-6 text-left pull-left">
                    <h6 className="mb-2 font-weight-bold">Employees</h6>
                </div>
                <div className="col-md-6 text-right pull-right">
                    <a href={dashboard} className="mb-2 font-weight-bold">Back</a>           
                </div>
            </div>
        </div>
        <div className="col-md-10 bg-white m-auto shadow-sm p-4" style={{ borderTop: '5px solid #DF8E2E' }}>
            <div className='row'>
                <div className="col-md-6 text-right pull-right">
                    &nbsp;
                </div>
                <div className="col-md-6 text-right pull-right">
                    <a href="#" onClick={handleShow} className="btn btn-primary rounded-0"> <i className="bi bi-plus"></i> Add Employee</a>
                </div>
            </div>
            <table data-toggle="table" data-classes="table table-hover table-condensed"
                data-striped="true" data-sort-name="Quality" data-sort-order="desc" id="Tabla"
                className="Tabla table table-hover table-condensed" data-page-length='10'>
                <thead>
                    <tr>
                        <th >First Name</th>
                        <th >Last Name</th>
                        <th >Role/Designation</th>
                        <th >Phone</th>
                        <th >Email</th>
                        <th >Gender</th>
                        <th >Education</th>
                        <th >Work Experience</th>
                        <th >Date of Joining</th>
                        <th data-sortable="true">Employee ID</th>
                    </tr>
                </thead>
                <tbody>
                    {employees.map(employee =>(
                    <tr key={employee.id}>
                        <td>{employee.firstname}</td>
                        <td>{employee.lastname}</td>
                        <td>{employee.designation}</td>
                        <td>{employee.phone}</td>
                        <td>{employee.email}</td>
                        <td>{employee.gender}</td>
                        <td>{employee.education}</td>
                        <td>{employee.workexperience}</td>
                        <td>{employee.dateofjoin}</td>
                        <td>{employee.employeeid}</td>
                    </tr>
                    ))

                    }
                </tbody>
            </table>
        </div>
        <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter"><span className="pl-2"> Add Employee </span></Modal.Title>
            </Modal.Header>
            <form method="POST" onSubmit={handleSubmit}>
            <Modal.Body className='modal-body'>                
                    <div className="col-md-12 p-2 border customer_records" style={{ backgroundColor: '#F6F6F6' }}>
                        <div className="mb-3">
                            <input type="text" className="form-control  rounded-0 textOnly mt-2" id="validationTooltip01" name="firstname" onChange={handleChange} placeholder="First name*" />
                            <div className="invalid-feedback small">{errors.firstname}</div>
                            <input type="text" className="form-control  rounded-0 textOnly mt-2" id="validationTooltip01" name="lastname" onChange={handleChange} placeholder="Last name*" />
                            <div className="invalid-feedback small">{errors.lastname}</div>
                            <input type="email" className="form-control  rounded-0 textOnly mt-2" id="validationTooltip01" name="email" onChange={handleChange} placeholder="Email*" />
                            <div className="invalid-feedback small">{errors.email}</div>
                            <PhoneInput  style={{ width:'100% !important' }} country={'us'} onlyCountries={['us']} name="phone" value={phoneNumber} onChange={handlePhoneChange}/>
                            <div className="invalid-feedback small">{errors.phone}</div>
                            <input type="text" className="form-control  rounded-0 textOnly mt-2" id="validationTooltip01" name="designation" onChange={handleChange} placeholder="Designation*" />
                            <div className="invalid-feedback small">{errors.designation}</div>
                            <input type="text" className="form-control  rounded-0 textOnly mt-2" id="validationTooltip01" name="country" onChange={handleChange} value="USA" placeholder="Country*" />
                            <div className="invalid-feedback small">{errors.country}</div>
                            <select className="form-select rounded-0 mt-2" id="state" name="state" onChange={handleChange} aria-label="Default select example">
                                <option value="">Select State *</option>
                                { states.map(state =>(
                                    <option  key={state.id} value={state.name}>{state.name}</option>
                                ))}
                            </select>
                            <div className="invalid-feedback small">{errors.state}</div>
                            <select className="form-select rounded-0 mt-2" id="state" name="gender" onChange={handleChange} aria-label="Default select example">
                                <option value="">Select Gender *</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                            </select>
                            <div className="invalid-feedback small">{errors.gender}</div>
                            <input type="text" className="form-control  rounded-0 textOnly mt-2" id="validationTooltip01" name="education" onChange={handleChange} placeholder="Education*" />
                            <div className="invalid-feedback small">{errors.education}</div>
                            <input type="text" className="form-control  rounded-0 textOnly mt-2" id="validationTooltip01" name="workexperience" onChange={handleChange} placeholder="Work Experience*" />
                            <div className="invalid-feedback small">{errors.workexperience}</div>
                            <input type="date" className="form-control  rounded-0 textOnly mt-2" id="validationTooltip01" name="dateofjoin" onChange={handleChange} placeholder="Date of join *" />
                            <div className="invalid-feedback small">{errors.dateofjoin}</div>                        
                            <div className="alert-success small">{message}</div>
                        </div>
                    </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="mb-2 col-md-12 mt-5 text-center">
                    <button type="reset" className="btn btn-secondary ml-2" data-dismiss="modal">Cancel</button>
                    <button type="submit" className="btn btn-primary ml-2" onClick={validateForm}>Submit</button>
                </div>
            </Modal.Footer>
            </form>
        </Modal>
    </div>
  )
}

export default Employees
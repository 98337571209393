import React,{useState,useEffect} from 'react';
import { useParams } from 'react-router-dom';
import AuthUser from '../AuthUser';
function Resetpassword(props) {
    let usertype = props.logintype;
    let loginurl= usertype === 'applicant' ? '/applicantlogin' : usertype === 'employer' ? '/employerlogin' : usertype === 'attorney' ? '/attorneylogin' : '/'; 
    let apiurl = props.apiurl;
    const {http,setToken} = AuthUser();
    const [password, setPassword] = useState("Abc.@678");
    const [values,setValues]=useState({password:'',confpassword:''});
    const [errors, setErrors] = useState({password:'',confpassword:'' });
    const [successMessage, setSuccessMessage] = useState('');
    const {token,email} = useParams();
    const [errorMessage, setErrorMessage] = useState("");
    const [submitted, setsubmitted] = useState(false);
    const [message,setMessage]=useState();
    const[show,setShow]=useState(false);
    const validateForm = () => {
        let isValid = true;
        const newErrors = { ...errors };
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        // Validate email
        if (!values.password.trim()) {
            newErrors.password = 'Password is required';
            isValid = false;
        } else {
            newErrors.password = '';
        }

        // Validate password
        if (!values.confpassword.trim()) {
            newErrors.confpassword = 'Password is required';
            isValid = false;
        } else {
            newErrors.confpassword = '';
        }

        setErrors(newErrors);
        return isValid;
    };
    const handlePassword = (event) => {
        let new_pass = event.target.value;
        let new_name = event.target.name;
        
        setPassword(new_pass);
        setValues({...values,[new_name]:event.target.value})
        const newErrors = { ...errors };
        // regular expressions to validate password
        var lowerCase = /[a-z]/g;
        var upperCase = /[A-Z]/g;
        var numbers = /[0-9]/g;
        if (!new_pass.match(lowerCase)) {
            newErrors.new_name = "Password should contains lowercase letters!";
        } else if (!new_pass.match(upperCase)) {
            newErrors.new_name = "Password should contain uppercase letters!";
        } else if (!new_pass.match(numbers)) {
            newErrors.new_name = "Password should contains numbers also!";
        } else if (new_pass.length < 8) {
            newErrors.new_name = "Password length should be more than 8.";
        } else {
            newErrors.new_name='';
        }
    }
    const handleSubmit = async (e) =>{
        e.preventDefault();
        //validation(values);
        let password = values.password;
        let confpassword = values.confpassword;        
        let token = e.target.token.value;
        let email = e.target.email.value;
        const newErrors = { ...errors };
        if (validateForm()) {
            if(props.pageview === 'Create Password'){
                var body = {pswd:password,confirm_password:confpassword,email:email};
            }else{
                var body = {pswd:password,confirm_password:confpassword,token:token,email:email};
            }
          http.post(`${usertype}/${apiurl}`,body).then((response)=>{
              //setToken(response.data.userdetail,response.data.token);
              //console.log(response.status);
              console.log(response.data);
              setMessage('Password created successfully!');
              setShow(true);
              
          }).catch(function (error) {
            // handle error
            console.log(error);
            //console.log(error.response.data.error);
            
            if(error?.response?.status==403){
              newErrors.password = error.response.data?.error?.password;
              newErrors.confpassword = error.response.data?.error?.confpassword;
              setErrors(newErrors);
            }
            if(error.response.status==401){
              newErrors.message = error.response.data?.error;
              setErrors(newErrors);
            }
            
            //setsubmitted(false)
          })
        } else {
          // Form is not valid, display error messages
    
        }
    }
  return (
    <div className="container-fluid">
        <div className="col-md-4 m-auto p-0 pb-2 ">
            <h6 className="mb-2 font-weight-bold">{props.pageview}</h6>
        </div>
        <div className="col-md-4 bg-white m-auto shadow-sm p-4" style={{ borderTop: '5px solid #DF8E2E' }}>
            {show === true ?
            
            <div className='row'>
                <div className="alert-success small">{message}</div>
                <p>Click here to <a href={loginurl}>login</a></p>
            </div>: 
            <form method="POST" onSubmit={handleSubmit} id="forgotpost">
                <div className="alert-success small">{message}</div>
                <div className="mb-3">
                    <label for='password' className="form-label" style={{ color: '#2D8BB4' }}><strong>Create New Password</strong></label>
                    <input type="password" id='pswd' name='password' className="form-control rounded-0" onChange={handlePassword} />
                    <input type="hidden" name="token" value={token} />
                    <input type="hidden" name="email" value={email} />
                    <div className="invalid-feedback small">{errors.password}</div>
                </div>
                <div className="mb-3">
                    <label className="form-label" style={{ color: '#2D8BB4' }}><strong>Reconfirm Password</strong></label>
                    <input type="password" id='confirm_password' name='confpassword' className="form-control rounded-0" onChange={handlePassword} />
                    <div className="invalid-feedback small">{errors.confpassword}</div>
                </div>
                <div className="d-grid gap-2 col-8 mx-auto mt-3">
                    <button className="btn text-white" type="submit" onClick={validateForm} disabled={submitted} style={{ backgroundColor: '#81A744' }}>Submit</button>
                </div>
            </form>
            
            }
        </div>
    </div>
  )
}

export default Resetpassword
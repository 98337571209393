import React,{useState,useEffect} from 'react'
import AuthUser from '../AuthUser';
import Doc from '../images/doc.png'
import Pdf from '../images/pdf.png'
import Viewrequestdocument from './Viewrequestdocument';
function Requestslist(props) {
    let usertype = props.logintype;
    let dashboard=`/${usertype}`;
    let apiurl = props.apiurl;
    const { http, getUser } = AuthUser();
    let userdetails = getUser();
    const [suggestions,setSuggestions] = useState([]);
    const [file, setFile] = useState(false);
    const [modalshow, setModalshow] = useState(false);
    const handleModalShow = (filename) =>{
        setModalshow(true);
        setFile(filename);
    } 
    function fetchItems(){
        http.get(`${usertype}/${apiurl}/${userdetails.email}`)
        .then((response) => {
            console.log(response.data);
            setSuggestions(response.data.requests);
        })
        .catch(function (error) {
            // handle error
            console.log(error.response.data.error);
        });
    }
    useEffect(() => {
        fetchItems();
    }, []);
  return (
    <div className="container-fluid">
        <div className="col-md-9 m-auto p-0 pb-2 ">
            <div className='row'>
                <div className="col-md-6 text-left pull-left">
                    <h6 className="mb-2 font-weight-bold">Request</h6>
                </div>
                <div className="col-md-6 text-right pull-right">
                    <a href={dashboard} className="mb-2 font-weight-bold">Back</a>           
                </div>
            </div>
        </div>
        <div className="col-md-9 bg-white m-auto shadow-sm p-4" style={{ borderTop: '5px solid #DF8E2E' }}>
            
            <table data-toggle="table" data-classes="table table-hover table-condensed"
                data-striped="true" data-sort-name="Quality" data-sort-order="desc" id="Tabla"
                className="Tabla table table-hover table-condensed" data-page-length='10'>
                <thead>
                    <tr>
                        <th >First Name</th>
                        <th >First Name</th>
                        <th >Subject</th>                        
                        <th >Message</th> 
                        <th ><i className="bi bi-paperclip"></i></th>
                        <th data-sortable="true">Created Date</th>
                    </tr>
                </thead>
                <tbody>
                    {suggestions.map(suggestion =>(
                        <tr key={suggestion.id}>
                            <td>{suggestion.name}</td>
                            <td>{suggestion.lastname}</td>
                            <td>{suggestion.subject}</td>
                            <td>{suggestion.message}</td>
                            <td ><a href="#" onClick={() => handleModalShow(suggestion.file_name)}><img src={suggestion.file_name.split('.').pop() == 'pdf' ? Pdf : Doc} />{suggestion.old_name}</a></td>
                            <td >{suggestion.created_at}</td>
                        </tr>
                    ))

                    }
                </tbody>
            </table>
        </div>
        <Viewrequestdocument show={modalshow} filename={file} foldertype="empselfdocncertificates" close={() => setModalshow(false)}/>
    </div>
  )
}

export default Requestslist
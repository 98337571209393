import React,{useState,useEffect} from 'react'
import AuthUser from '../AuthUser';
import Doc from '../images/doc.png';
import Pdf from '../images/pdf.png';
import { useParams } from 'react-router-dom';
import Dropdown from "react-bootstrap/Dropdown";
import Modal from 'react-bootstrap/Modal';
import Viewrequestdocument from './Viewrequestdocument';
function Timesheetdetailreport(props) {
    const [months,setMonths]=useState(
    {
        '01': "Jan",'02': "Feb",'03': "Mar",'04': "Apr",'05': "May",'06': "Jun",'07': "Jul",'08': "Aug",'09': "Sep",'10': "Oct",'11': "Nov",'12': "Dec"
    });
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a
            href=""
            ref={ref}
            onClick={e => {
            e.preventDefault();
            onClick(e);
            }}
        >
            {children}
            <span className="threedots" />
        </a>
    ));
    const {month} = useParams();
    const { http, getUser } = AuthUser();
    let usertype=props.logintype;
    let dashboard=`/${usertype}/timesheet`;
    let userdetails = getUser();
    const [file, setFile] = useState(false);
    const [show, setShow] = useState(false);
    const [timesheetreports,setTimesheetreports]=useState([]);
    const [reportof,setReportof]=useState('');
    const [year,setYear]=useState('');
    const [modalshow, setModalshow] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [selectedid, setSelectedid] = useState(null);
    const now = new Date();
    const current = now.getFullYear();
    const previous = current - 1;
    const handleModalShow = (filename) =>{
        setModalshow(true);
        setFile(filename);
    }
    function fetchItems(){
        if(month == current || month == previous){           
            var url = `${usertype}/timesheetreport/${userdetails.email}?year=${month}`;
        }else{
            var url = `${usertype}/timesheetreport/${userdetails.email}?id=${month}`;
        }
        http.get(url)
        .then((response) => {
            console.log(response.data);
            setTimesheetreports(response.data.timesheetreports);
            setReportof(response.data.reportof);
            setYear(response.data.year);
        })
        .catch(function (error) {
            // handle error
            console.log(error.response.data.error);
        });
    }
    useEffect(() => {
        fetchItems();
        
    }, []);
    const handleStatus = (val, id) =>{
        let ut = usertype === 'employer' ? 'E' : 'AD';
        http.get(`/${usertype}/chageTimesheet?ticketid=${id}&statusval=${val}&userType=${ut}&userId=${userdetails.id}`)
        .then((response) => {
            fetchItems();
        })
        .catch(function (error) {
            // handle error
            console.log(error.response.data.error);
        });
    }
    const handleDownload = (filename) =>{
        //console.log(filename);
        http.post(`${usertype}/timesheetdownload`,{file:filename}, {
            responseType: 'blob',
        })
        .then((response) => {
            //console.log(response);
            //let filename = filename;
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url; //''
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        })
        .catch(function (error) {
            // handle error
            console.log('There was an error downloading the file!', error);
        });
    }
    
  return (
    <div className="container-fluid">
        <div className="col-md-9  m-auto p-0 pb-2 ">
            <div className='row'>
                <div className="col-md-6 text-left pull-left">
                    <h6 className="mb-2 font-weight-bold">Timesheet | {reportof} {year}</h6>
                </div>
                <div className="col-md-6 text-right pull-right">
                    <a href={dashboard} className="mb-2 font-weight-bold">Back</a>           
                </div>
            </div>
        </div>
        <div className="col-md-9 bg-white m-auto shadow-sm p-4" style={{ borderTop: '5px solid #DF8E2E' }}>
            
            <table data-toggle="table" data-classes="table table-hover table-condensed"
                data-striped="true" data-sort-name="Quality" data-sort-order="desc" id="Tabla"
                className="Tabla table table-hover table-condensed" data-page-length='10'>
                <thead>
                    <tr>
                        <th >Employee Name</th>
                        <th >Month</th>
                        <th >Year</th>
                        <th >Working Hours</th>
                        <th ><i className="bi bi-paperclip"></i></th>
                        <th >Status</th>
                        <th data-sortable="true">Submitted Date & Time</th>
                    </tr>
                </thead>
                <tbody>
                    {timesheetreports.map(timesheetreport =>(
                        <tr>
                            <th >{timesheetreport.name}&nbsp;{timesheetreport.lastname}</th>
                            <th >{months[timesheetreport.month]}</th>
                            <th >{year}</th>
                            <th >{timesheetreport.workhrs}</th>
                            <th >
                                <div className="row">
                                    <div className="col-9" ><a href="#" onClick={() => handleModalShow(timesheetreport.file_name)}><img src={timesheetreport.file_name.split('.').pop() == 'pdf' ? Pdf : Doc} />{timesheetreport.old_name}</a> </div>
                                    <div className="col-3">
                                        
                                        <Dropdown>
                                            <Dropdown.Toggle as={CustomToggle} />
                                            <Dropdown.Menu size="sm" title="">
                                                <Dropdown.Item href="#" onClick={()=>handleDownload(timesheetreport.file_name)}>Download</Dropdown.Item>
                                                <Dropdown.Item href="#" onClick={()=>handleModalShow(timesheetreport.file_name)}>View</Dropdown.Item>
                                                <Dropdown.Item href="#" onClick={()=>handleStatus('A',timesheetreport.id)} className="text-success">Approve</Dropdown.Item>
                                                <Dropdown.Item href="#" onClick={()=>handleStatus('R',timesheetreport.id)} className="text-danger">Reject</Dropdown.Item>
                                                <Dropdown.Item href="#" onClick={()=>handleStatus('D',timesheetreport.id)} className="text-danger">Dispute</Dropdown.Item>
                                                <Dropdown.Item href="#" onClick={()=>handleStatus('DL',timesheetreport.id)} className="text-danger">Delete</Dropdown.Item>                                                                   
                                            </Dropdown.Menu>
                                        </Dropdown> 
                                    </div>
                                </div>
                            </th>
                            <th >
                                {  (timesheetreport.status == 'P')  ? 'Pending' : 
                                        ((timesheetreport.status == 'A') ? 'Approved' : 
                                        (timesheetreport.status == 'R') ? 'Rejected' : 
                                        (timesheetreport.status == 'D') ? 'Dispute' : 'Deletion under Process' )
                                            
                                                
                                }
                            </th>
                            <th data-sortable="true">{timesheetreport.created_at}</th>
                        </tr>
                    ))}
                    
                </tbody>
            </table>
        </div>
        <Viewrequestdocument show={modalshow} filename={file} foldertype="empselftimesheets" close={() => setModalshow(false)} />        
    </div>
  )
}

export default Timesheetdetailreport
import React,{useState} from 'react'
import AuthUser from "../AuthUser";

function Suggestions() {
    const [values,setValues]=useState({ subject:'',referenceurl:'',comment:''});
    const [errors, setErrors] = useState({ subject: '',referenceurl: '',comment:'',message:'' });
    const { http, getUser } = AuthUser();
    const [message,setMessage]= useState();
    let userdetails = getUser();
    let usertype = 'applicant';
    let dashboard=`/${usertype}`;
    const validateForm = () => {
        let isValid = true;
        const newErrors = { ...errors };
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        // Subject
        if (!values.subject.trim()) {
            newErrors.subject = 'Subject is required';
            isValid = false;
        } else {
            newErrors.title = '';
        }
        // Message
        if (!values.referenceurl.trim()) {
            newErrors.referenceurl = 'Reference url is required';
            isValid = false;
        } else {
            newErrors.referenceurl = '';
        }
        // File Upload
        if (!values.comment.trim()) {
            newErrors.comment = 'Comment is required';
            isValid = false;
        } else {
            newErrors.comment = '';
        }

        setErrors(newErrors);
        setMessage('');
        return isValid;
    };
    function handleChange(e){
        setMessage('');
        setValues({...values,[e.target.name]:e.target.value});
    }
    const handleSubmit = async(e) =>{
        e.preventDefault();
        let subject = values.subject;
        let referenceurl = values.referenceurl;
        let comment = values.comment;
        const newErrors = { ...errors };
        
        if (validateForm()) {
          http.post(`applicant/suggestion/${userdetails.email}`,{subject:subject,referenceurl:referenceurl,comment:comment,userid:userdetails.id}).then((response)=>{
              //setToken(response.data.userdetail,response.data.token,usertype);
              //console.log(response.status);
              console.log(response.data.message);
              setValues({
                subject: '',
                document: '',
                message: '',
              });
              e.target.reset();
              setMessage('Suggestion sent successfully!');
              
          }).catch(function (error) {
            // handle error
            //console.log(error.response.data.error);
            if(error.response.status==403){
                newErrors.subject = error.response.data?.error?.subject;
                newErrors.referenceurl = error.response.data?.error?.referenceurl;
                newErrors.comment = error.response.data?.error?.comment;
                setErrors(newErrors);
            }
            if(error.response.status==401){
                newErrors.message = error.response.data?.error;
                setErrors(newErrors);
            }
            //setsubmitted(false)
          })
        } else {
          // Form is not valid, display error messages
    
        }
    }
  return (
    <div className="container-fluid">
        <div className="col-md-6 m-auto p-0 pb-2 ">
            <div className='row'>
                <div className="col-md-6 text-left pull-left">
                    <h6 className="mb-2 font-weight-bold">Suggestions</h6>
                </div>
                <div className="col-md-6 text-right pull-right">
                    <a href={dashboard} className="mb-2 font-weight-bold">Back</a>           
                </div>
            </div>
        </div>
        <div className="col-md-6 bg-white m-auto shadow-sm p-4" style={{ borderTop: '5px solid #DF8E2E' }}>
            <form  method="POST" onSubmit={handleSubmit}>
                <div className="alert-success small"></div>
                <div class="col-lg-12 p-1">
                    <h6 class="mb-2">Help us improve our product? Give us your valuable suggestions.</h6>
                    <div class="row">
                        <div class="col-lg-12 mt-4">
                            <input type="text" class="form-control textOnly" placeholder="Subject" name="subject" onChange={handleChange} />
                            <div className="invalid-feedback small">{errors.subject}</div>
                        </div>
                        <div class="col-lg-12 mt-4">
                            <input type="text" class="form-control" placeholder="Reference URL" name="referenceurl" onChange={handleChange}  />
                            <div className="invalid-feedback small">{errors.referenceurl}</div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 mt-4">
                            <div class="form-group">
                                <textarea class="form-control" id="exampleFormControlTextarea1" rows="5" onChange={handleChange}  placeholder="Comments..." name="comment"></textarea>
                            </div>
                            <div className="invalid-feedback small">{errors.comment}</div>
                            <div className="invalid-feedback small">{errors.message}</div>
                            <div className="alert-success small">{message}</div>
                        </div>
                    </div>
                </div>
                <div className="d-grid gap-2 col-8 mx-auto mt-3">
                    <button className="btn text-white" type="submit" onClick={validateForm} style={{ backgroundColor: '#81A744' }}>Submit</button>
                </div>
            </form>
        </div>
    </div>
  )
}

export default Suggestions
import React,{useState,useEffect} from 'react'
import AuthUser from '../AuthUser';
import Table from 'react-bootstrap/Table';
function Viewinvites(props) {
  const [inviteslist, setInviteslist] = useState([]);
  const [filter, setFilter] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const { http, getUser } = AuthUser();
  let userdetails = getUser();
    useEffect(() => {
        http.get(`employer/viewinvitesapi/${userdetails.email}`)
        .then((response) => {
            console.log(response.data);
            setInviteslist(response.data.inviteslist);
            setFilteredData(response.data.inviteslist);
        })
        .catch(function (error) {
            // handle error
            console.log(error.response.data.error);
        });
    }, []);
    const handleFilterChange = (e) => {
        const value = e.target.value.toLowerCase();
        setFilter(value);

        const filtered = inviteslist.filter(item =>
            item.email.toLowerCase().includes(value) // Add more conditions based on your data structure
        );

        setFilteredData(filtered);
    };
  return (
    <div class="container-fluid">
      <div class="row mb-2 ">
          <div class="col-md-4">
              <nav aria-label="breadcrumb">
                  <ol class="breadcrumb p-0">
                      <li class="breadcrumb-item "><a href="/employer">Dashboard</a></li>
                      <li class="breadcrumb-item active" aria-current="page">Invitee Applicants</li>
                  </ol>
              </nav>
          </div>
          <div class="col-md-4 small  text-right">
              <div class="row">
                  <input type="text" name="email" value={filter}
                onChange={handleFilterChange} class="form-control searchEmail" placeholder="Search by Email" />
              </div>
          </div>
          <div class="col-md-4 text-right">
              
          </div>
      </div>        
      <div class="row">
        <div class="col-md-12 pb-5">
            <div class="card  mb-4">
                <div class="card-body">
                    <div class="table-responsive">
                        <table data-toggle="table" className="Tabla table table-hover table-condensed" data-striped="true" data-sort-name="Quality" data-sort-order="desc" id="Tabla"
                             data-page-length='10'>
                            <thead>
                                <tr>
                                    <th data-sortable="true">User Email</th>
                                    <th data-sortable="true">Date and Time</th>    
                                </tr>
                            </thead>
                            <tbody>
                              {filteredData.map(invite =>(
                                <tr key={invite.id}>
                                    <td>
                                        <div className="row">
                                            <div className="col-9">{invite.useremail}</div>
                                        </div>
                                    </td>
                                    <td >{invite.created_at}</td>
                                </tr>
                            ))

                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default Viewinvites